

// import React, { useState, useEffect } from 'react';
// import { getAuthToken } from '../../../../auth_token/authUtils';
// import { Box, Typography, Paper, Snackbar, Alert } from '@mui/material';
// import { Bar } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
// import moment from 'moment';
// import Baseurl from '../../../../baseurl/baserurl';

// // Register chart.js components
// ChartJS.register(
//     CategoryScale,
//     LinearScale,
//     BarElement,
//     Title,
//     Tooltip,
//     Legend
// );

// export default function StudentOfTheMonth() {
//     const [attendanceData, setAttendanceData] = useState([]);
//     const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

//     useEffect(() => {
//         const fetchAttendanceData = async () => {
//             try {
//                 const user = JSON.parse(localStorage.getItem('user'));
//                 const school_id = user?.school_id;
//                 const teacher_id = user?.teacher_id;

//                 if (!school_id) {
//                     handleSnackbar('School ID is missing.', 'warning');
//                     return;
//                 }

//                 const token = getAuthToken();
//                 if (!token) {
//                     handleSnackbar('Sorry, you are not authenticated. Please re-login.', 'error');
//                     window.location.href = '/'; // Redirect to home page
//                     window.location.reload(); // Force reload the page
//                     return;
//                 }

//                 // Fetch data from the studentOfTheMonth API
//                 const response = await fetch(`${Baseurl.baseURL}api/tution_attendance/studentOfTheMonth`, {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                         Authorization: `Bearer ${token}`,
//                     },
//                     body: JSON.stringify({
//                         school_id,
//                         // teacher_id,
//                     }),
//                 });

//                 if (response.ok) {
//                     const data = await response.json();
//                     if (data && data.data && Array.isArray(data.data)) {
//                         setAttendanceData(data.data); // Store the attendance data
//                     } else {
//                         handleSnackbar('No data found or data is in an unexpected format.', 'info');
//                     }
//                 } else {
//                     const error = await response.json();
//                     handleSnackbar('Error fetching attendance data: ' + error.message, 'error');
//                 }
//             } catch (error) {
//                 handleSnackbar('An error occurred. Please try again.', 'error');
//             }
//         };

//         // Fetch data initially
//         fetchAttendanceData();

//         // Set up interval to fetch data every 50 seconds
//         const intervalId = setInterval(fetchAttendanceData, 50000);

//         // Clear interval on component unmount
//         return () => clearInterval(intervalId);
//     }, []);

//     const handleSnackbar = (message, severity) => {
//         setSnackbar({ open: true, message, severity });
//     };

//     const handleCloseSnackbar = () => {
//         setSnackbar({ open: false, message: '', severity: 'info' });
//     };

//     // Get the current month
//     const currentMonth = moment().format('YYYY-MM');

//     // Aggregate data by student_name and count present/absent days
//     const aggregatedData = attendanceData.reduce((acc, record) => {
//         const recordMonth = moment(record.date).format('YYYY-MM');

//         if (recordMonth === currentMonth) {
//             const existingEntry = acc.find((entry) => entry.student_name === record.student_name);

//             if (existingEntry) {
//                 // Update counts
//                 if (record.status === 'Present') {
//                     existingEntry.present_count += 1;
//                 } else if (record.status === 'Absent') {
//                     existingEntry.absent_count += 1;
//                 }
//             } else {
//                 // Create a new entry for the student
//                 acc.push({
//                     student_name: record.student_name,
//                     present_count: record.status === 'Present' ? 1 : 0,
//                     absent_count: record.status === 'Absent' ? 1 : 0,
//                 });
//             }
//         }

//         return acc;
//     }, []);

//     // Sort students by present_count in descending order (highest first)
//     const sortedData = [...aggregatedData].sort((a, b) => b.present_count - a.present_count);

//     // Data for the chart
//     const chartData = {
//         labels: sortedData.map((entry) => entry.student_name),
//         datasets: [
//             {
//                 label: 'Present Days',
//                 data: sortedData.map((entry) => entry.present_count),
//                 backgroundColor: '#069938',
//                 borderColor: 'black',
//                 borderWidth: 1,
//             },
//             {
//                 label: 'Absent Days',
//                 data: sortedData.map((entry) => entry.absent_count),
//                 backgroundColor: '#eb5e49',
//                 borderColor: 'black',
//                 borderWidth: 1,
//             },
//         ],
//     };

//     // Options for the chart
//     const chartOptions = {
//         responsive: true,
//         plugins: {
//             legend: {
//                 position: 'top',
//             },
//             tooltip: {
//                 callbacks: {
//                     label: function (tooltipItem) {
//                         return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
//                     },
//                 },
//             },
//         },
//         scales: {
//             x: {
//                 ticks: {
//                     color: 'black', // Set x-axis labels to hard black color
//                 },
//             },
//             y: {
//                 ticks: {
//                     color: 'black', // Set y-axis labels to hard black color
//                 },
//             },
//         },
//     };

//     return (
//         <Box>
//             <Typography variant="h4" gutterBottom>
//                 Student Attendance for the Month
//             </Typography>
//             <Box>
//                 {sortedData.length > 0 && (
//                     <Bar data={chartData} options={chartOptions} />
//                 )}
//             </Box>
//             {/* Snackbar for alerts */}
//             <Snackbar
//                 open={snackbar.open}
//                 autoHideDuration={6000}
//                 onClose={handleCloseSnackbar}
//                 anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
//             >
//                 <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
//                     {snackbar.message}
//                 </Alert>
//             </Snackbar>
//         </Box>
//     );
// }


import React, { useState, useEffect } from 'react';
import { getAuthToken } from '../../../../auth_token/authUtils';
import { Box, Typography, Paper, Snackbar, Alert, FormControl, Select, MenuItem } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import moment from 'moment';
import Baseurl from '../../../../baseurl/baserurl';

// Register chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function StudentOfTheMonth() {
    const [attendanceData, setAttendanceData] = useState([]);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
    const [selectedStudent, setSelectedStudent] = useState('');

    useEffect(() => {
        const fetchAttendanceData = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                const school_id = user?.school_id;

                if (!school_id) {
                    handleSnackbar('School ID is missing.', 'warning');
                    return;
                }

                const token = getAuthToken();
                if (!token) {
                    handleSnackbar('Sorry, you are not authenticated. Please re-login.', 'error');
                    window.location.href = '/'; // Redirect to home page
                    return;
                }

                // Fetch data from the studentOfTheMonth API
                const response = await fetch(`${Baseurl.baseURL}api/tution_attendance/studentOfTheMonth`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ school_id }),
                });

                if (response.ok) {
                    const data = await response.json();
                    if (data && data.data && Array.isArray(data.data)) {
                        setAttendanceData(data.data); // Store the attendance data
                    } else {
                        handleSnackbar('No data found or data is in an unexpected format.', 'info');
                    }
                } else {
                    const error = await response.json();
                    handleSnackbar('Error fetching attendance data: ' + error.message, 'error');
                }
            } catch (error) {
                handleSnackbar('An error occurred. Please try again.', 'error');
            }
        };

        // Fetch data initially
        fetchAttendanceData();

        // Set up interval to fetch data every 50 seconds
        const intervalId = setInterval(fetchAttendanceData, 50000);

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const handleSnackbar = (message, severity) => {
        setSnackbar({ open: true, message, severity });
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ open: false, message: '', severity: 'info' });
    };

    // Get the current month
    const currentMonth = moment().format('YYYY-MM');

    // Aggregate data by student_name and count present/absent days
    const aggregatedData = attendanceData.reduce((acc, record) => {
        const recordMonth = moment(record.date).format('YYYY-MM');

        if (recordMonth === currentMonth) {
            const existingEntry = acc.find((entry) => entry.student_name === record.student_name);

            if (existingEntry) {
                // Update counts
                if (record.status === 'Present') {
                    existingEntry.present_count += 1;
                } else if (record.status === 'Absent') {
                    existingEntry.absent_count += 1;
                }
            } else {
                // Create a new entry for the student
                acc.push({
                    student_name: record.student_name,
                    present_count: record.status === 'Present' ? 1 : 0,
                    absent_count: record.status === 'Absent' ? 1 : 0,
                });
            }
        }

        return acc;
    }, []);

    // Sort students by present_count in descending order (highest first)
    const sortedData = [...aggregatedData].sort((a, b) => b.present_count - a.present_count);

    // Data for the chart
    const studentData = selectedStudent
        ? sortedData.filter(entry => entry.student_name === selectedStudent)
        : sortedData;

    const chartData = {
        labels: studentData.map((entry) => entry.student_name),
        datasets: [
            {
                label: 'Present Days',
                data: studentData.map((entry) => entry.present_count),
                backgroundColor: '#069938',
                borderColor: 'black',
                borderWidth: 1,
            },
            {
                label: 'Absent Days',
                data: studentData.map((entry) => entry.absent_count),
                backgroundColor: '#eb5e49',
                borderColor: 'black',
                borderWidth: 1,
            },
        ],
    };

    // Options for the chart
    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
                    },
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    color: 'black', // Set x-axis labels to hard black color
                },
            },
            y: {
                ticks: {
                    color: 'black', // Set y-axis labels to hard black color
                    // Use the following function to display whole numbers
                    callback: function (value) {
                        return Number.isInteger(value) ? value : null; // Show only whole numbers
                    },
                },
                // Set the minimum value to 0 and configure step size
                min: 0,
                max: Math.max(...sortedData.map(entry => entry.present_count)) + 1, // Adjust maximum based on data
                title: {
                    display: true,
                    text: 'Days',
                    color: 'black',
                },
            },
        },
    };

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Student Attendance for the Month
            </Typography>
            <FormControl fullWidth margin="normal">
                <Select
                    value={selectedStudent}
                    onChange={(e) => setSelectedStudent(e.target.value)}
                    displayEmpty
                >
                    <MenuItem value="">
                        <em>All</em>
                    </MenuItem>
                    {sortedData.map((student) => (
                        <MenuItem key={student.student_name} value={student.student_name}>
                            {student.student_name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Box>
                {studentData.length > 0 && (
                    <Bar data={chartData} options={chartOptions} />
                )}
            </Box>
            {/* Snackbar for alerts */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}
