import image6 from '../../../../../assets_n/image6.jpg';

const AboutInfo = () => {
  return (
    <div className="about-info">
        <div className="vc-office">
            <h1>Rohit Singh<br /> <h6>Founder ,Studee</h6></h1>
            <p>Rohit Singh, the founder of Studee, believes that while there are numerous ERP solutions for students in India, many of them come with high costs that make them inaccessible to a significant portion of the student population. His mission is to bridge this gap and ensure that every student in India has access to effective educational tools and resources.</p><br />
            <p>At Studee, we are driven by a relentless pursuit of academic excellence and a commitment to fostering a vibrant learning community. Our institution stands as a beacon of knowledge, providing a transformative educational experience that empowers individuals to lead, innovate, and make a positive impact on the world.</p>
        </div>
        <div className="vc-image">
            <img src={image6} alt="vc-image" />
        </div>
    </div>
  )
}

export default AboutInfo
