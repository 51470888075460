import React, { useEffect, useState } from 'react';
import { Container, Typography, Paper, Button } from '@mui/material';
import axios from 'axios';
import Baseurl from '../../../baseurl/baserurl';
import { useNavigate, useParams } from 'react-router-dom';

const NoteDetail = ({ match }) => {
    const [note, setNote] = useState(null);
    const { id: noteId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchNoteDetail = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${Baseurl.baseURL}api/Notepaid/getNotePaid/${noteId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setNote(response.data.note);
            } catch (error) {
                console.error('Error fetching note details:', error);
            }
        };

        fetchNoteDetail();
    }, [noteId]);

    if (!note) {
        return <Typography variant="h6">Loading note details...</Typography>;
    }

    return (
        <Container maxWidth="md" sx={{ marginTop: 4 }}>
            <Button
                variant="outlined"
                onClick={() => navigate('/tution-my-notepaid')} // Navigate to the specified route
                sx={{ marginTop: 2 }} // Add some margin
            >
                Go Back
            </Button>
            <Typography className="attendance-header" sx={{ textAlign: 'center', fontSize: '30px' }}>{note.subject}</Typography>
            <Paper sx={{ padding: 2 }}>
                <Typography variant="body1" sx={{ marginTop: 2 }}
                    dangerouslySetInnerHTML={{ __html: note.describe }} // Render HTML content here
                />
            </Paper>
        </Container>
    );
};

export default NoteDetail;
