 

// import React, { useState, useEffect } from 'react';
// import { getAuthToken } from '../../../../auth_token/authUtils';
// import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Snackbar, Alert } from '@mui/material';
// import CrownIcon from '@mui/icons-material/EmojiEvents'; // Crown-like icon from Material UI
// import Baseurl from '../../../../baseurl/baserurl';


// export default function StudentOfTheMonth() {
//   const [attendanceData, setAttendanceData] = useState([]);
//   const [notification, setNotification] = useState({ message: '', severity: 'info', open: false });

//   const handleNotification = (message, severity = 'info') => {
//     setNotification({ message, severity, open: true });
//   };

//   const handleClose = () => {
//     setNotification((prev) => ({ ...prev, open: false }));
//   };

//   useEffect(() => {
//     const fetchAttendanceData = async () => {
//       try {
//         const user = JSON.parse(localStorage.getItem('user'));
//         const school_id = user?.school_id;
//         const teacher_id = user?.teacher_id;

//         if (!school_id) {
//           handleNotification('School ID or Teacher ID is missing.', 'error');
//           return;
//         }

//         const token = getAuthToken();
//         if (!token) {
//           handleNotification('Sorry, you are not authenticated. Please re-login.', 'error');
//           window.location.href = '/'; // Redirect to home page
//           window.location.reload(); // Force reload the page
//           return;
//         }

//         // Fetch data from the API
//         const response = await fetch(`${Baseurl.baseURL}api/tution_attendance/studentOfTheyear`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${token}`,
//           },
//           body: JSON.stringify({
//             school_id,
//             // teacher_id,
//           }),
//         });

//         if (response.ok) {
//           const data = await response.json();
//           if (data && data.data && Array.isArray(data.data)) {
//             setAttendanceData(data.data); // Store the attendance data
//           } else {
//             handleNotification('No data found or data is in an unexpected format.', 'warning');
//           }
//         } else {
//           const error = await response.json();
//           handleNotification('Error fetching attendance data: ' + error.message, 'error');
//         }
//       } catch (error) {
//         handleNotification('An error occurred. Please try again.', 'error');
//       }
//     };

//     // Fetch data initially
//     fetchAttendanceData();

//     // Set up interval to fetch data every 50 seconds
//     const intervalId = setInterval(fetchAttendanceData, 50000);

//     // Clear interval on component unmount
//     return () => clearInterval(intervalId);
//   }, []);

//   // Aggregate data by student_name and count present/absent days across all dates
//   const aggregatedData = attendanceData.reduce((acc, record) => {
//     const existingEntry = acc.find((entry) => entry.student_name === record.student_name);

//     if (existingEntry) {
//       // Update counts
//       if (record.status === 'Present') {
//         existingEntry.present_count += 1;
//       } else if (record.status === 'Absent') {
//         existingEntry.absent_count += 1;
//       }
//       existingEntry.total_days += 1; // Increment total days
//     } else {
//       // Create a new entry for the student
//       acc.push({
//         student_name: record.student_name,
//         present_count: record.status === 'Present' ? 1 : 0,
//         absent_count: record.status === 'Absent' ? 1 : 0,
//         total_days: 1, // Initialize total days
//       });
//     }

//     return acc;
//   }, []);

//   // Sort students by present_count in descending order (highest first)
//   const sortedData = [...aggregatedData].sort((a, b) => b.present_count - a.present_count);

//   return (
//     <Box>
//       <Typography variant="h6" gutterBottom>
//         Student Attendance
//       </Typography>
//       <TableContainer component={Paper} sx={{ maxHeight: 500, overflow: 'auto' }}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>Student Name</TableCell>
//               <TableCell align="center">Present Days</TableCell>
//               <TableCell align="center">Absent Days</TableCell>
//               <TableCell align="center">Total Days</TableCell> {/* New column */}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {sortedData.map((row, index) => (
//               <TableRow key={row.student_name} style={index === 0 ? { backgroundColor: '#a2fac0' } : {}}>
//                 <TableCell>
//                   {index === 0 && <CrownIcon style={{ marginRight: '8px', color: 'gold' }} />}
//                   {row.student_name}
//                 </TableCell>
//                 <TableCell align="center">{row.present_count}</TableCell>
//                 <TableCell align="center">{row.absent_count}</TableCell>
//                 <TableCell align="center">{row.total_days}</TableCell> {/* New column */}
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>

//       {/* Snackbar for notifications */}
//       <Snackbar open={notification.open} autoHideDuration={6000} onClose={handleClose}>
//         <Alert onClose={handleClose} severity={notification.severity} sx={{ width: '100%' }}>
//           {notification.message}
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// }


import React, { useState, useEffect } from 'react';
import { getAuthToken } from '../../../../auth_token/authUtils';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import CrownIcon from '@mui/icons-material/EmojiEvents'; // Crown-like icon from Material UI
import Baseurl from '../../../../baseurl/baserurl';

export default function StudentOfTheMonth() {
  const [attendanceData, setAttendanceData] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(''); // State for selected student
  const [notification, setNotification] = useState({ message: '', severity: 'info', open: false });

  const handleNotification = (message, severity = 'info') => {
    setNotification({ message, severity, open: true });
  };

  const handleClose = () => {
    setNotification((prev) => ({ ...prev, open: false }));
  };

  useEffect(() => {
    const fetchAttendanceData = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        const school_id = user?.school_id;
        const teacher_id = user?.teacher_id;

        if (!school_id) {
          handleNotification('School ID or Teacher ID is missing.', 'error');
          return;
        }

        const token = getAuthToken();
        if (!token) {
          handleNotification('Sorry, you are not authenticated. Please re-login.', 'error');
          window.location.href = '/'; // Redirect to home page
          window.location.reload(); // Force reload the page
          return;
        }

        // Fetch data from the API
        const response = await fetch(`${Baseurl.baseURL}api/tution_attendance/studentOfTheYear`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            school_id,
            // teacher_id,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          if (data && data.data && Array.isArray(data.data)) {
            setAttendanceData(data.data); // Store the attendance data
          } else {
            handleNotification('No data found or data is in an unexpected format.', 'warning');
          }
        } else {
          const error = await response.json();
          handleNotification('Error fetching attendance data: ' + error.message, 'error');
        }
      } catch (error) {
        handleNotification('An error occurred. Please try again.', 'error');
      }
    };

    // Fetch data initially
    fetchAttendanceData();

    // Set up interval to fetch data every 50 seconds
    const intervalId = setInterval(fetchAttendanceData, 50000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // Aggregate data by student_name and count present/absent days across all dates
  const aggregatedData = attendanceData.reduce((acc, record) => {
    const existingEntry = acc.find((entry) => entry.student_name === record.student_name);

    if (existingEntry) {
      // Update counts
      if (record.status === 'Present') {
        existingEntry.present_count += 1;
      } else if (record.status === 'Absent') {
        existingEntry.absent_count += 1;
      }
      existingEntry.total_days += 1; // Increment total days
    } else {
      // Create a new entry for the student
      acc.push({
        student_name: record.student_name,
        present_count: record.status === 'Present' ? 1 : 0,
        absent_count: record.status === 'Absent' ? 1 : 0,
        total_days: 1, // Initialize total days
      });
    }

    return acc;
  }, []);

  // Sort students by present_count in descending order (highest first)
  const sortedData = [...aggregatedData].sort((a, b) => b.present_count - a.present_count);

  // Handle student selection
  const handleStudentSelect = (event) => {
    setSelectedStudent(event.target.value);
  };

  // Filter the data based on selected student
  const filteredData = selectedStudent
    ? sortedData.filter((row) => row.student_name === selectedStudent)
    : sortedData;

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Student Attendance
      </Typography>

      {/* Dropdown menu for selecting a student */}
      <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
        <InputLabel id="student-select-label">Select Student</InputLabel>
        <Select
          labelId="student-select-label"
          value={selectedStudent}
          onChange={handleStudentSelect}
          label="Select Student"
        >
          <MenuItem value="">
            <em>All Students</em>
          </MenuItem>
          {sortedData.map((row) => (
            <MenuItem key={row.student_name} value={row.student_name}>
              {row.student_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TableContainer component={Paper} sx={{ maxHeight: 500, overflow: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Student Name</TableCell>
              <TableCell align="center">Present Days</TableCell>
              <TableCell align="center">Absent Days</TableCell>
              <TableCell align="center">Total Days</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((row, index) => (
              <TableRow key={row.student_name} style={index === 0 ? { backgroundColor: '#a2fac0' } : {}}>
                <TableCell>
                  {index === 0 && <CrownIcon style={{ marginRight: '8px', color: 'gold' }} />}
                  {row.student_name}
                </TableCell>
                <TableCell align="center">{row.present_count}</TableCell>
                <TableCell align="center">{row.absent_count}</TableCell>
                <TableCell align="center">{row.total_days}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Snackbar for notifications */}
      <Snackbar open={notification.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={notification.severity} sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
