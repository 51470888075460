// import * as React from 'react';
// import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import Typography from '@mui/material/Typography';
// import Container from '@mui/material/Container';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import { setAuthToken } from '../../auth_token/authUtils'; // Import utility functions
// import { Box } from '@mui/material';
// import Baseurl from '../../baseurl/baserurl';

// const defaultTheme = createTheme();

// const SignIn = () => {
//   const navigate = useNavigate();

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const data = new FormData(event.currentTarget);

//     try {
//       // const response = await axios.post('https://app.negohtelbulletin.media/api/auth/login', {
//       const response = await axios.post(`${Baseurl.baseURL}api/auth/login`, {
//         email: data.get('email'),
//         password: data.get('password'),
//       });

//       const { token, user } = response.data;

//       if (token) {
//         setAuthToken(token, user);

//         if (user.role === 'student') {
//           navigate('/student-dashboard');
//         } else if (user.role === 'teacher') {
//           navigate('/teacher-dashboard');
//         } else if (user.role === 'admin') {
//           navigate('/admin-dashboard');
//         } else if (user.role === 'superadmin') {
//           navigate('/super-admin-dashboard');
//         } else {
//           navigate('/'); // Default case if role is unrecognized
//         }

//         window.location.reload();
//       } else {
//         alert('Login failed');
//       }
//     } catch (error) {
//       console.error('Login error:', error);
//       alert('An error occurred. Please try again.');
//     }
//   };

//   return (
//     <ThemeProvider theme={defaultTheme}>
//       <Container component="main" maxWidth="xs" sx={{ height: '100%', padding: 0 }}>
//         <CssBaseline />
//         <Box
//           sx={{
//             height: '100%',
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             // backgroundImage: 'url(/studee_logo.png)', // Replace with your background image path
//             backgroundSize: 'cover',
//             backgroundRepeat: 'no-repeat',
//             backgroundPosition: 'center',
//             backgroundColor: 'transparent', // Add a slight overlay to darken the background
//             animation: 'fadeIn 1s ease-in-out',
//             padding: 0,
//           }}
//         >
//           <Box
//             sx={{
//               backgroundColor: 'white',
//               borderRadius: 2,
//               boxShadow: 3,
//               p: 4,
//               width: '100%',
//               maxWidth: '400px',
//               animation: 'slideIn 0.5s ease-in-out',
//               textAlign: 'center',
//               position: 'relative', // Add relative positioning for the avatar
//             }}
//           >
//             <Avatar
//               sx={{
//                 m: 'auto', // Center horizontally
//                 bgcolor: 'secondary.main',
//                 width: 56,
//                 height: 56,
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//                 position: 'absolute', // Absolute positioning within the box
//                 top: '-28px', // Adjust based on your layout
//                 left: '50%',
//                 transform: 'translateX(-50%)', // Center horizontally
//               }}
//             >
//               <img
//                 src="/studee_logo.png" // Replace with your avatar image path
//                 alt="Avatar"
//                 style={{ width: '100%', height: '100%', objectFit: 'cover' }}
//               />
//             </Avatar>
//             <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
//               Welcome to Studee
//             </Typography>
//             <Box component="form" onSubmit={handleSubmit} noValidate>
//               <TextField
//                 margin="normal"
//                 required
//                 fullWidth
//                 id="email"
//                 label="Email Address"
//                 name="email"
//                 autoComplete="email"
//                 autoFocus
//                 sx={{ mb: 2 }}
//               />
//               <TextField
//                 margin="normal"
//                 required
//                 fullWidth
//                 name="password"
//                 label="Password"
//                 type="password"
//                 id="password"
//                 autoComplete="current-password"
//                 sx={{ mb: 2 }}
//               />
//               <FormControlLabel
//                 control={<Checkbox value="remember" color="primary" />}
//                 label="Remember me"
//               />
//               <Button
//                 type="submit"
//                 fullWidth
//                 variant="contained"
//                 sx={{ mt: 3, mb: 2 }}
//               >
//                 Sign In
//               </Button>
//             </Box>
//           </Box>
//         </Box>
//       </Container>
//       <style jsx global>{`
//         @keyframes fadeIn {
//           from {
//             opacity: 0;
//           }
//           to {
//             opacity: 1;
//           }
//         }
//         @keyframes slideIn {
//           from {
//             transform: translateY(-20px);
//             opacity: 0;
//           }
//           to {
//             transform: translateY(0);
//             opacity: 1;
//           }
//         }
//       `}</style>
//     </ThemeProvider>
//   );
// }

// export default SignIn;



// import * as React from 'react';
// import { useNavigate } from 'react-router-dom';
// import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import Typography from '@mui/material/Typography';
// import Container from '@mui/material/Container';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Dialog from '@mui/material/Dialog';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import Box from '@mui/material/Box';
// import Drawer from '@mui/material/Drawer';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemText from '@mui/material/ListItemText';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
// import CloseIcon from '@mui/icons-material/Close';
// import axios from 'axios';
// import Baseurl from '../../baseurl/baserurl';
// import { setAuthToken } from '../../auth_token/authUtils';

// const defaultTheme = createTheme();

// const SignIn = () => {
//   const navigate = useNavigate();
//   const [open, setOpen] = React.useState(false); // Dialog open/close
//   const [drawerOpen, setDrawerOpen] = React.useState(false); // Drawer open/close

//   const handleOpen = () => setOpen(true); // Open dialog
//   const handleClose = () => setOpen(false); // Close dialog

//   const toggleDrawer = (state) => () => setDrawerOpen(state); // Toggle drawer

//   const handleLinkClick = (route) => {
//     navigate(route);
//     setDrawerOpen(false); // Close drawer after navigation
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const data = new FormData(event.currentTarget);

//     try {
//       const response = await axios.post(`${Baseurl.baseURL}api/auth/login`, {
//         email: data.get('email'),
//         password: data.get('password'),
//       });

//       const { token, user } = response.data;

//       if (token) {
//         setAuthToken(token, user);

//         // Navigate based on role
//         switch (user.role) {
//           case 'student':
//             navigate('/student-dashboard');
//             break;
//           case 'teacher':
//             navigate('/teacher-dashboard');
//             break;
//           case 'admin':
//             navigate('/admin-dashboard');
//             break;
//           case 'superadmin':
//             navigate('/super-admin-dashboard');
//             break;
//           case 'tution_teacher':
//             navigate('/tution_teacher-dashboard');
//             break;
//           case 'tution_student':
//             navigate('/tution_student-dashboard');
//             break;
//           default:
//             navigate('/');
//         }

//         window.location.reload(); // Refresh after login
//       } else {
//         alert('Login failed');
//       }
//     } catch (error) {
//       console.error('Login error:', error);
//       alert('An error occurred. Please try again.');
//     }
//   };

//   return (
//     <ThemeProvider theme={defaultTheme}>
//       <CssBaseline />

//       {/* Header with background image and navigation links */}
//       <Box
//         sx={{
//           background: 'blue',
//           backgroundSize: 'cover',
//           backgroundPosition: 'center',
//           minHeight: 'auto',
//           display: 'flex',
//           flexDirection: 'column',
//           justifyContent: 'space-between',
//         }}
//       >
//         {/* Navbar */}
//         <AppBar position="static" sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
//           <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
//             <Typography variant="h6" component="div">
//               Web Dev Creative
//             </Typography>
//             <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
//               <Button color="inherit" onClick={() => navigate('/')}>Home</Button>
//               <Button color="inherit" onClick={() => navigate('/about')}>About</Button>
//               <Button color="inherit" onClick={() => navigate('/services')}>Services</Button>
//               <Button color="inherit" onClick={() => navigate('/contact')}>Contact</Button>
//               <Button variant="outlined" color="inherit" onClick={handleOpen} sx={{ ml: 2 }}>
//                 Get Started
//               </Button>
//             </Box>

//             {/* Menu icon for mobile view */}
//             <IconButton
//               edge="start"
//               color="inherit"
//               aria-label="menu"
//               sx={{ display: { xs: 'block', md: 'none' } }}
//               onClick={toggleDrawer(true)}
//             >
//               <MenuIcon />
//             </IconButton>
//           </Toolbar>
//         </AppBar>

//         {/* Drawer for mobile view */}
//         <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
//           <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
//             <IconButton onClick={toggleDrawer(false)}>
//               <CloseIcon />
//             </IconButton>
//           </Box>
//           <List sx={{ width: 250 }}>
//             <ListItem button onClick={() => handleLinkClick('/')}>
//               <ListItemButton>
//                 <ListItemText primary="Home" />
//               </ListItemButton>
//             </ListItem>
//             <ListItem button onClick={() => handleLinkClick('/about')}>
//               <ListItemButton>
//                 <ListItemText primary="About" />
//               </ListItemButton>
//             </ListItem>
//             <ListItem button onClick={() => handleLinkClick('/services')}>
//               <ListItemButton>
//                 <ListItemText primary="Services" />
//               </ListItemButton>
//             </ListItem>
//             <ListItem button onClick={() => handleLinkClick('/contact')}>
//               <ListItemButton>
//                 <ListItemText primary="Contact" />
//               </ListItemButton>
//             </ListItem>
//             <ListItem button onClick={() => { handleOpen(); setDrawerOpen(false); }}>
//               <ListItemButton>
//                 <ListItemText primary="Get Started" />
//               </ListItemButton>
//             </ListItem>
//           </List>
//         </Drawer>

//         {/* Main Content - Hero Section */}
//         <Box
//           sx={{
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'center',
//             justifyContent: 'center',
//             textAlign: 'center',
//             flexGrow: 1,
//           }}
//         >
//           <Typography variant="h1" sx={{ color: '#fff', fontSize: { xs: '2.5rem', md: '4rem' } }}>
//             Welcome
//           </Typography>
//           <Typography variant="h6" sx={{ color: '#fff', mt: 2 }}>
//             Lorem ipsum dolor sit amet consectetur adipiscing elit. Aliquam, atque.
//           </Typography>
//         </Box>
//       </Box>

//       {/* Login Popup Dialog */}
//       <Dialog open={open} onClose={handleClose}>
//         <Container component="main" maxWidth="xs">
//           <Box
//             sx={{
//               marginTop: 4,
//               display: 'flex',
//               flexDirection: 'column',
//               alignItems: 'center',
//               position: 'relative',
//             }}
//           >
//             <Avatar
//               sx={{
//                 m: 1,
//                 bgcolor: 'secondary.main',
//                 width: 56,
//                 height: 56,
//                 position: 'absolute',
//                 top: '-28px',
//               }}
//             >
//               <img
//                 src="/studee_logo.png"
//                 alt="Avatar"
//                 style={{ width: '100%', height: '100%', objectFit: 'cover' }}
//               />
//             </Avatar>
//             <Typography component="h1" variant="h5" sx={{ mt: 6 }}>
//               Welcome to Studee
//             </Typography>
//             <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
//               <TextField
//                 margin="normal"
//                 required
//                 fullWidth
//                 id="email"
//                 label="Email Address"
//                 name="email"
//                 autoComplete="email"
//                 autoFocus
//               />
//               <TextField
//                 margin="normal"
//                 required
//                 fullWidth
//                 name="password"
//                 label="Password"
//                 type="password"
//                 id="password"
//                 autoComplete="current-password"
//               />
//               <FormControlLabel
//                 control={<Checkbox value="remember" color="primary" />}
//                 label="Remember me"
//               />
//               <Button
//                 type="submit"
//                 fullWidth
//                 variant="contained"
//                 sx={{ mt: 3, mb: 2 }}
//               >
//                 Sign In
//               </Button>
//             </Box>
//           </Box>
//         </Container>
//       </Dialog>
//     </ThemeProvider>
//   );
// };

// export default SignIn;

import React from 'react';
import Navbarr from './homepage/new/navbar';
// import Ourusp from './homepage/ourUSP';
import Home from './homepage/new/home';

function login() {
  return (
    <>
      <Navbarr />
      <Home />
      {/* <Ourusp /> */}

    </>
  )
}

export default login