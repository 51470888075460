import React from 'react';
import Navbar from "../navbar";
import ContactUs from '../components/ContactUs';
import Footer from '../components/Footer';

const Contact = () => {
  return (
    <>
      <Navbar />
      <div className="pages contact">
        <h1 className="title">Contact Us</h1>
      </div>
      <div className="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31581.42350482591!2d77.3088709091875!3d28.577493249642028!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390c9cc0466d9a57%3A0x403eb7b74cc55b34!2sSector%2019A%2C%20Noida%2C%20Uttar%20Pradesh!5e0!3m2!1sen!2sin!4v1690918491480!5m2!1sen!2sin"
          style={{ border: 0, width: '100%', height: '400px' }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade">
        </iframe>
      </div>
      <ContactUs />
      <Footer />
    </>
  )
}

export default Contact
