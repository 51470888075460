// import React from 'react'
// import { Link } from 'react-router-dom'

// const AdmissionInfo = () => {
//   return (
//     <div className="ad-info">
//         <p>Expand your horizon and tailor your studies according to your interest and aspirations. It's your path to discover</p>
//         <Link to="/programs">Discover what Studee has to offer you</Link>
//     </div>
//   )
// }

// export default AdmissionInfo

import React from 'react';
import { Link } from 'react-router-dom';

const AdmissionInfo = () => {
  return (
    <div className="ad-info">
        <p>Empower your education with personalized learning experiences at Studee. Shape your future and achieve your dreams with us!</p>
        <Link to="/programs">Explore what Studee can offer to elevate your learning journey</Link>
    </div>
  );
}

export default AdmissionInfo;
