// import React, { useEffect, useState } from 'react';
// import { Line } from 'react-chartjs-2';
// import 'chart.js/auto';
// import { getAuthToken } from '../../../../auth_token/authUtils';
// import zoomPlugin from 'chartjs-plugin-zoom';
// import Baseurl from '../../../../baseurl/baserurl';


// const AttendanceGraph = () => {
//   const [attendanceData, setAttendanceData] = useState([]);
//   const [labels, setLabels] = useState([]);
//   const todayDate = new Date().toISOString().split('T')[0]; // Current date in 'YYYY-MM-DD' format

//   useEffect(() => {
//     const fetchAttendanceData = async () => {
//       const user = JSON.parse(localStorage.getItem('user'));
//       const school_id = user?.school_id;
//       const token = getAuthToken();

//       if (!school_id || !token) return; // Early return if token or school_id is not available

//       try {
//         const response = await fetch(`${Baseurl.baseURL}api/tution_attendance/studentOfTheMonth`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${token}`, // Add token for Authorization
//           },
//           body: JSON.stringify({
//             school_id,
//             // date: todayDate,  // Include the date in the request body
//           }),
//         });

//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }

//         const data = await response.json();
//         processAttendanceData(data.data);  // Call your function to process the data
//       } catch (error) {
//         console.error('Error fetching attendance data:', error);
//       }
//     };

//     fetchAttendanceData();
//   }, []);

//   // Function to generate the dates for the current month (1 to 31)
//   const generateMonthDates = () => {
//     const now = new Date();
//     const year = now.getFullYear();
//     const month = now.getMonth();
//     const daysInMonth = new Date(year, month + 1, 0).getDate();

//     const monthDates = Array.from({ length: daysInMonth }, (_, i) => {
//       return `${i + 1}`; // Use day numbers as labels
//     });

//     setLabels(monthDates);
//   };

//   useEffect(() => {
//     generateMonthDates();
//   }, []);

//   // Function to process the attendance data
//   const processAttendanceData = (data) => {
//     // Process data to handle multiple students and dates
//     const students = {};

//     data.forEach(record => {
//       const { student_name, date, homework } = record;
//       const day = new Date(date).getDate(); // Get the day of the month
//       // const day = new Date(date).toLocaleDateString('en-GB');

//       if (!students[student_name]) {
//         students[student_name] = [];
//       }
//       // Store homework completion for each day
//       students[student_name].push({
//         day,
//         homeworkCompleted: homework === 'Yes' ? 1 : 0
//       });
//     });

//     // Convert to array format for chart
//     const processedData = Object.keys(students).map(student_name => ({
//       name: student_name,
//       attendanceDays: students[student_name]
//     }));

//     setAttendanceData(processedData);
//   };

//   // Function to generate the data for the chart
//   const generateChartData = () => {
//     const datasets = attendanceData.map((student) => ({
//       label: student.name,
//       data: labels.map((day) => {
//         const dayRecord = student.attendanceDays.find(dayRecord => dayRecord.day === parseInt(day));
//         return dayRecord ? dayRecord.homeworkCompleted : 0;
//       }),
//       borderColor: getRandomColor(),
//       backgroundColor: 'rgba(0, 0, 0, 0)',
//       fill: false,
//       borderWidth: 2
//     }));

//     return {
//       labels,
//       datasets
//     };
//   };

//   // Random color generator
//   const getRandomColor = () => {
//     return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
//   };

//   return (
//     <div>
//       <h2>Student Attendance & Homework Performance</h2>
//       {attendanceData.length > 0 && labels.length > 0 ? (
//         <Line
//           data={generateChartData()}
//           options={{
//             responsive: true,
//             plugins: {
//               zoom: {
//                 zoom: {
//                   wheel: {
//                     enabled: true,
//                   },
//                   pinch: {
//                     enabled: true,
//                   },
//                   drag: {
//                     enabled: true,
//                   },
//                 },
//                 pan: {
//                   enabled: true,
//                   mode: 'xy',
//                 }
//               },
//               tooltip: {
//                 callbacks: {
//                   label: function (tooltipItem) {
//                     const value = tooltipItem.raw; // Get the value of the point
//                     return `${tooltipItem.label}: ${value === 1 ? 'Yes' : 'No'}`; // Display 'Yes' for 1 and 'No' for 0
//                   }
//                 }
//               }
//             },
//             scales: {
//               y: {
//                 beginAtZero: true,
//                 title: {
//                   display: true,
//                   text: 'Homework Completed (1 = Yes, 0 = No)',
//                 },
//               },
//               x: {
//                 title: {
//                   display: true,
//                   text: 'Days of the Month',
//                 },
//                 ticks: {
//                   autoSkip: true,
//                   maxTicksLimit: 10, // Limit the number of ticks to avoid overlap
//                 }
//               },
//             },
//           }}

//         />
//       ) : (
//         <p>Loading data...</p>
//       )}
//     </div>
//   );
// };

// export default AttendanceGraph;


import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { getAuthToken } from '../../../../auth_token/authUtils';
import zoomPlugin from 'chartjs-plugin-zoom';
import Baseurl from '../../../../baseurl/baserurl';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material';

const AttendanceGraph = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState('');
  const todayDate = new Date().toISOString().split('T')[0]; // Current date in 'YYYY-MM-DD' format

  useEffect(() => {
    const fetchAttendanceData = async () => {
      const user = JSON.parse(localStorage.getItem('user'));
      const school_id = user?.school_id;
      const token = getAuthToken();

      if (!school_id || !token) return; // Early return if token or school_id is not available

      try {
        const response = await fetch(`${Baseurl.baseURL}api/tution_attendance/studentOfTheMonth`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, // Add token for Authorization
          },
          body: JSON.stringify({
            school_id,
            // date: todayDate,  // Include the date in the request body
          }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        processAttendanceData(data.data);  // Call your function to process the data
      } catch (error) {
        console.error('Error fetching attendance data:', error);
      }
    };

    fetchAttendanceData();
  }, []);

  // Function to generate the dates for the current month (1 to 31)
  const generateMonthDates = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    const monthDates = Array.from({ length: daysInMonth }, (_, i) => {
      return `${i + 1}`; // Use day numbers as labels
    });

    setLabels(monthDates);
  };

  useEffect(() => {
    generateMonthDates();
  }, []);

  // Function to process the attendance data
  const processAttendanceData = (data) => {
    // Process data to handle multiple students and dates
    const students = {};

    data.forEach(record => {
      const { student_name, date, homework } = record;
      const day = new Date(date).getDate(); // Get the day of the month

      if (!students[student_name]) {
        students[student_name] = [];
      }
      // Store homework completion for each day
      students[student_name].push({
        day,
        homeworkCompleted: homework === 'Yes' ? 1 : 0
      });
    });

    // Convert to array format for chart
    const processedData = Object.keys(students).map(student_name => ({
      name: student_name,
      attendanceDays: students[student_name]
    }));

    setAttendanceData(processedData);
    if (processedData.length > 0) {
      setSelectedStudent(processedData[0].name); // Set the first student as the default selected
    }
  };

  // Function to generate the data for the chart
  const generateChartData = () => {
    if (!selectedStudent) return { labels, datasets: [] }; // No student selected, return empty data

    const studentData = attendanceData.find(student => student.name === selectedStudent);

    const dataset = {
      label: studentData.name,
      data: labels.map((day) => {
        const dayRecord = studentData.attendanceDays.find(dayRecord => dayRecord.day === parseInt(day));
        return dayRecord ? dayRecord.homeworkCompleted : 0;
      }),
      borderColor: getRandomColor(),
      backgroundColor: 'rgba(0, 0, 0, 0)',
      fill: false,
      borderWidth: 2
    };

    return {
      labels,
      datasets: [dataset] // Return only the selected student's data
    };
  };

  // Random color generator
  const getRandomColor = () => {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'column' }, gap: 2 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h5">Student Attendance & Homework Performance</Typography>
        <FormControl fullWidth variant="outlined" sx={{}}>
          <InputLabel id="student-select-label">Select Student</InputLabel>
          <Select
            labelId="student-select-label"
            value={selectedStudent}
            onChange={(e) => setSelectedStudent(e.target.value)}
            label="Select Student"
          >
            {attendanceData.map((student) => (
              <MenuItem key={student.name} value={student.name}>
                {student.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {attendanceData.length > 0 && labels.length > 0 ? (
          <Line
            data={generateChartData()}
            options={{
              responsive: true,
              plugins: {
                zoom: {
                  zoom: {
                    wheel: {
                      enabled: true,
                    },
                    pinch: {
                      enabled: true,
                    },
                    drag: {
                      enabled: true,
                    },
                  },
                  pan: {
                    enabled: true,
                    mode: 'xy',
                  }
                },
                tooltip: {
                  callbacks: {
                    label: function (tooltipItem) {
                      const value = tooltipItem.raw; // Get the value of the point
                      return `${tooltipItem.label}: ${value === 1 ? 'Yes' : 'No'}`; // Display 'Yes' for 1 and 'No' for 0
                    }
                  }
                }
              },
              scales: {
                y: {
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: 'Homework Completed (1 = Yes, 0 = No)',
                  },
                },
                x: {
                  title: {
                    display: true,
                    text: 'Days of the Month',
                  },
                  ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10, // Limit the number of ticks to avoid overlap
                  }
                },
              },
            }}
          />
        ) : (
          <CircularProgress />
        )}
      </Box>
    </Box>
  );
};

export default AttendanceGraph;
