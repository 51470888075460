
// import React, { useEffect } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import { BiHome, BiBookAlt, BiMessage, BiSolidReport } from 'react-icons/bi';
// import { Button } from '@mui/material';
// import "../style/sidebar.css";

// function Sidebar() {
//     const navigate = useNavigate();

//     useEffect(() => {
//         const checkToken = () => {
//             const token = localStorage.getItem('token');
//             if (!token) {
//                 navigate('/');
//             }
//         };

//         // Initial check
//         checkToken();

//         // Set up the interval to check every 5 seconds (5000 ms)
//         const interval = setInterval(checkToken, 5000);

//         // Clear the interval when the component unmounts
//         return () => clearInterval(interval);
//     }, [navigate]);

//     // const handleLogout = (event) => {
//     //     event.preventDefault(); // Prevent default button behavior

//     //     // Clear authentication data
//     //     localStorage.removeItem('token');
//     //     localStorage.removeItem('tokenExpiration');
//     //     localStorage.removeItem('user');
//     //     console.log("Logging out and redirecting...");
//     //     // Optional: If using a state management library, update state here

//     //     // Redirect to login page
//     //     navigate('/');
//     //     window.location.href = '/'; // Redirect to home page
//     //     window.location.reload(); // Force reload the page
//     // };
//     const handleLogout = async (event) => {
//         if (event) event.preventDefault();
//         try {
//             // Clear local storage
//             localStorage.removeItem('token');
//             localStorage.removeItem('tokenExpiration');
//             localStorage.removeItem('user');
//             // setIsOpen(false);

//             // Redirect to login page
//             setTimeout(() => {
//                 window.location.href = '/';
//             }, 1000);
//         } catch (error) {
//             console.error('Logout error:', error);
//         }
//     };

//     return (
//         <div className="menu">
//             <div className="logo">
//                 {/* <BiBookAlt className='logo-icon' /> */}
//                 <img src="/studee_logo.png" alt="Studee" className="logo-icon" width={70} height={70} style={{ borderRadius: '50px' }} />
//                 <h2>Studee</h2>
//             </div>
//             <div className="menu--list">
//                 <Link to="/super-admin-dashboard" className="item">
//                     <BiHome className='icon' />
//                     Dashboard
//                 </Link>
//                 <Link to="/super-admin-detail" className="item">
//                     <BiMessage className='icon' />
//                     All School Detail
//                 </Link>
//                 <Link to="/super-admin-detail-edit" className="item">
//                     <BiSolidReport className='icon' />
//                     School Detail Edit
//                 </Link>
//             </div>
//             <div className="logout">
//                 <Button
//                     variant="contained"
//                     color="error"
//                     onClick={handleLogout}
//                 >
//                     Logout
//                 </Button>
//             </div>
//         </div>
//     );
// }

// export default Sidebar;

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BiHome, BiBookAlt, BiMessage, BiSolidReport, BiMenu, BiX } from 'react-icons/bi';
import { Button } from '@mui/material';
import "../style/sidebar.css";

function Sidebar() {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkToken = () => {
            const token = localStorage.getItem('token');
            if (!token) {
                navigate('/');
            }
        };
        checkToken();
        const interval = setInterval(checkToken, 5000);
        return () => clearInterval(interval);
    }, [navigate]);

    // const handleLogout = (event) => {
    //     event.preventDefault();
    //     localStorage.removeItem('token');
    //     localStorage.removeItem('tokenExpiration');
    //     localStorage.removeItem('user');
    //     navigate('/');
    //     window.location.reload();
    // };
    const handleLogout = async (event) => {
        if (event) event.preventDefault();
        try {
            // Clear local storage
            localStorage.removeItem('token');
            localStorage.removeItem('tokenExpiration');
            localStorage.removeItem('user');
            setIsOpen(false);

            // Redirect to login page
            setTimeout(() => {
                window.location.href = '/';
            }, 1000);
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleLinkClick = () => {
        setIsOpen(false); // Close sidebar after link click
    };

    return (
        <div className={`sidebar-container ${isOpen ? 'open' : 'closed'}`}>
            <div className="toggle-btn" onClick={toggleSidebar}>
                {isOpen ? <BiX size={30} /> : <BiMenu size={30} />}
            </div>
            {isOpen && (
                <div className="menu">
                    <div className="logo">
                        {/* <BiBookAlt className='logo-icon' /> */}
                        <img src="/studee_logo.png" alt="Studee" className="logo-icon" width={70} height={70} style={{ borderRadius: '50px' }} />
                        <h2>Studee</h2>
                    </div>
                    <div className="menu--list">
                        <Link to="/super-admin-dashboard" className="item" onClick={handleLinkClick}>
                            <BiHome className='icon' />
                            Dashboard
                        </Link>
                        <Link to="/super-admin-detail" className="item" onClick={handleLinkClick}>
                            <BiMessage className='icon' />
                            All School Detail
                        </Link>
                        <Link to="/super-admin-detail-edit" className="item" onClick={handleLinkClick}>
                            <BiSolidReport className='icon' />
                            School Detail Edit
                        </Link>
                    </div>
                    <div className="logout">
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleLogout}
                        >
                            Logout
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Sidebar;
