import React from 'react';
import Navbar from "../login/homepage/new/navbar";
import Footer from '../login/homepage/new/components/Footer';
import { Container, Typography } from '@mui/material';

const Contact = () => {
    return (
        <>
            <Navbar />
            <div className="pages contact">
                <h1 className="title">Cancellation and Refund</h1>
            </div>
            {/* <div className="map">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3941.493879395246!2d7.555965373590163!3d8.926553490587121!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0f9db875403b%3A0x20bb27310124b188!2sLincoln%20college%20of%20science%20management%20and%20technology!5e0!3m2!1sen!2sng!4v1690918491480!5m2!1sen!2sng"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div> */}
            <Container>
                <Typography variant="h4" gutterBottom>
                    Cancellation and Refund Policy
                </Typography>
                <Typography variant="body1" paragraph>
                    Effective Date: 22-09-2024
                </Typography>
                <Typography variant="body1" paragraph>
                    We strive to provide high-quality services. However, if you need to cancel your order or request a refund, please follow these guidelines:
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>Cancellation:</strong> You can cancel your order within [insert time frame] by contacting us at [Contact Email].
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>Refunds:</strong> Refunds will be processed within [insert time frame] after we receive your cancellation request.
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>Conditions for Refund:</strong> Refunds are applicable for unused services or if we fail to provide the agreed services.
                </Typography>
                <Typography variant="body1" paragraph>
                    For further inquiries, please contact us at studeetech@gmail.com.
                </Typography>
            </Container>
            <Footer />
        </>
    )
}

export default Contact
