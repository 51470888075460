import { FaAngleDoubleDown, FaCertificate, FaChalkboardTeacher, FaEnvelope, FaPhone, FaQuoteRight, FaSchool } from 'react-icons/fa';
import image3 from '../../../../assets_n/image3.jpg';
import image4 from '../../../../assets_n/image4.webp';
import image7 from '../../../../assets_n/image7.jpg';
import image8 from '../../../../assets_n/image8.jpg';
import image9 from '../../../../assets_n/image9.jpeg';
import image10 from '../../../../assets_n/image10.jpg';
import image11 from '../../../../assets_n/image11.jpg';
import image12 from '../../../../assets_n/image12.jpg';
import image13 from '../../../../assets_n/image13.jpg';
import image14 from '../../../../assets_n/image14.png';
import image15 from '../../../../assets_n/image15.jpg';
import image16 from '../../../../assets_n/image16.jpg';

export const navLinks = [
    {
        id: 'Home',
        link: '/',
    },
    {
        id: 'About',
        link: '/about',
    },
    {
        id: 'Facilities',
        link: '/facility',
    },
    // {
    //     id: 'Programs',
    //     link: 'programs',
    // },
    {
        id: 'Contact Us',
        link: '/contact'
    },
    {
        id: 'Admission',
        link: '/apply',
    },
];

export const footerLinks = [
    {
        id: 'Privacy Policy',
        link: '/privacy-policy',
    },
    {
        id: 'Terms and Conditions',
        link: '/terms-and-conditions',
    },
    {
        id: 'Cancellation and Refund',
        link: '/cancellation-and-refund',
    },
    {
        id: 'Shipping and Delivery',
        link: '/shipping-and-delivery'
    },
    {
        id: 'Contact Us',
        link: '/contact',
    },
];

export const missionVision = [
    {
        id: 1,
        title: 'Our Mission',
        text: 'At Studee, our mission is to democratize education by providing accessible and affordable ERP solutions for every student in India. We aim to eliminate barriers to education, ensuring that every learner has access to the tools they need to excel academically and grow personally. We are dedicated to creating an inclusive learning environment that fosters collaboration, creativity, and a passion for lifelong learning.',
        icon: <FaAngleDoubleDown />,
    },
    {
        id: 2,
        title: 'Our Vision',
        text: 'At Studee, our vision is to be a leading force in educational innovation, setting new standards for accessibility and excellence in learning. We strive to empower every student with the resources they need to thrive, enabling them to become compassionate global citizens and leaders of tomorrow. Through continuous improvement and the integration of technology, we aspire to create a transformative educational experience that resonates with the diverse needs of students across India.',
        icon: <FaAngleDoubleDown />,
    },
];

export const whyStudee = [
    {
        id: 1,
        title: 'Good Facilities',
        reason: "We take pride in offering students an exceptional learning environment that is supported by top-notch facilities. We believe that a well-equipped setting is instrumental in enhancing our students' educational experience",
        icon: <FaSchool />,
    },
    {
        id: 2,
        title: 'Certificates',
        reason: 'A reason why students should come to our Studee is the opportunity to earn valuable certificates that can open doors to future success. These certificates are a testament to the diverse skills our students gain during their time with us',
        icon: <FaCertificate />,
    },
    {
        id: 3,
        title: 'Conducive Learning',
        reason: "At our Studee, we pride ourselves on creating a learning environment that is truly conducive to unleashing the full potential of each and every student. We invite you to join our vibrant learning community",
        icon: <FaChalkboardTeacher />,
    },
];

// export const testimonials = [
//     {
//         id: 1,
//         icon: <FaQuoteRight />,
//         image: image3,
//         name: 'Michael Chen',
//         testimonial: "I cannot express enough gratitude for the exceptional education I have received at Studee. The Studee's nurturing and supportive environment allowed me to flourish both academically and personally. The dedicated teachers and their innovative teaching methods sparked my curiosity and passion for learning",
//     },
//     {
//         id: 2,
//         icon: <FaQuoteRight />,
//         image: image4,
//         name: 'Sarah Johnson',
//         testimonial: 'My time at Studee has been nothing short of transformative. From the first day I stepped foot on campus, I felt welcomed and supported by the caring staffs and fellow students. I am grateful for the incredible opportunities I had at Studee, which have prepared me for a bright future in higher education and beyond.',
//     },
//     {
//         id: 3,
//         icon: <FaQuoteRight />,
//         image: image3,
//         name: 'John Doe',
//         testimonial: "I can confidently say that my time at Studee has been the most enriching and fulfilling experience of my life. The Studee's emphasis on holistic development helped me not only excel academically but also grow as an individual.",
//     },
//     {
//         id: 4,
//         icon: <FaQuoteRight />,
//         image: image4,
//         name: 'Emily Martinez',
//         testimonial: "I've been inspired to pursue my passions and have discovered a love for science that I never knew I had. Moreover, the sense of community at our Studee is heartwarming. I've made lifelong friends and feel like I'm part of a big family.",
//     },
//     {
//         id: 5,
//         icon: <FaQuoteRight />,
//         image: image3,
//         name: 'Anthony Joshua',
//         testimonial: 'I transferred to Studee during my sophomore year, and it was one of the best decisions I ever made. The transition was seamless, thanks to the warm and supportive environment created by both teachers and fellow students.',
//     },
// ];

// export const footerLinks = [
//     {
//         id: 1,
//         link: "Home",
//     },
//     {
//         id: 2,
//         link: "About",
//     },
//     {
//         id: 3,
//         link: "Facility",
//     },
//     {
//         id: 4,
//         link: "Programs",
//     },
//     {
//         id: 5,
//         link: "Apply",
//     },
// ]

export const testimonials = [
    {
        id: 1,
        icon: <FaQuoteRight />,
        image: image3,
        name: 'Aarav Sharma',
        testimonial: "Studee has truly been a turning point in my education. The teachers are incredibly supportive and genuinely care about our growth. I've improved in my studies and have made friends for life.",
    },
    {
        id: 2,
        icon: <FaQuoteRight />,
        image: image4,
        name: 'Anaya Patel',
        testimonial: 'Studee is more than just a school; it’s a community. I felt welcomed from day one, and the opportunities for learning and extracurricular activities have prepared me well for my future studies.',
    },
    {
        id: 3,
        icon: <FaQuoteRight />,
        image: image3,
        name: 'Rohan Gupta',
        testimonial: "My journey at Studee has been incredible. The balanced focus on academics and personal development helped me build confidence. I feel well-prepared for the challenges ahead.",
    },
    {
        id: 4,
        icon: <FaQuoteRight />,
        image: image4,
        name: 'Sneha Verma',
        testimonial: "Studee has ignited my passion for learning. The science labs and interactive classes are fantastic, and I’ve made great friends along the way. It truly feels like a family.",
    },
    {
        id: 5,
        icon: <FaQuoteRight />,
        image: image3,
        name: 'Vikram Singh',
        testimonial: 'Transferring to Studee was a decision I will never regret. The welcoming atmosphere and the guidance from teachers made it easy for me to adapt and excel in my studies.',
    },
];

export const footerContact = [
    {
        id: 1,
        text: "sector-19A, Noida",
    },
    {
        id: 2,
        text: "sector-19B, Noida",
    },
    {
        id: 3,
        text: "sector-12, Noida",
    },
    {
        id: 4,
        text: "sector-22, Noida",
    },
];

export const footerContactNum = [
    {
        id: 1,
        icon: <FaPhone />,
        text: "+91 9305676650",
    },
    {
        id: 2,
        icon: <FaEnvelope />,
        text: "info@studee.co.in",
    },
];

export const AboutPart = [
    {
        id: 1,
        image: image7,
        title: "Programs",
        text: "At Studee, our programs are designed to ignite curiosity and foster innovation among students. Through engaging hands-on experiments, collaborative projects, and interdisciplinary activities, we empower learners to explore the fascinating realms of science, technology, engineering, and beyond. Our goal is to inspire a lifelong love for learning and encourage students to become proactive problem solvers.",
    },
    {
        id: 2,
        image: image8,
        title: "Facilities",
        text: "We take pride in providing state-of-the-art facilities that create an ideal environment for holistic learning and growth. Our commitment to excellence extends beyond the classroom, ensuring that every aspect of a student's educational journey is supported. From modern classrooms to advanced laboratories and recreational spaces, we strive to offer an enriching environment that caters to diverse learning styles.",
    },
    {
        id: 3,
        image: image9,
        title: "Faculties",
        text: "Our faculties play a pivotal role in shaping the future of our students. With their unwavering dedication to academic excellence and holistic development, they create an inclusive and supportive environment where students can thrive. Our educators are committed to mentoring and inspiring learners, helping them transform into well-rounded individuals ready to make a positive impact on the world.",
    },
];

export const facilities = [
    {
        id: 1,
        image: image8,
        title: "Attendance Management",
        text: "Our comprehensive attendance management system ensures accurate tracking of student presence. This feature allows teachers to easily record attendance and students to view their attendance history, promoting accountability.",
    },
    {
        id: 2,
        image: image10,
        title: "Homework Reports",
        text: "Students and parents can access detailed homework reports through our platform. This feature enhances communication between teachers and families, ensuring that students stay on top of their assignments.",
    },
    {
        id: 3,
        image: image11,
        title: "Grade Tracking",
        text: "Our student ERP includes a robust grade tracking system, enabling students to monitor their academic performance in real-time. This transparency encourages students to take charge of their educational journey.",
    },
    {
        id: 4,
        image: image12,
        title: "Communication Portal",
        text: "The communication portal facilitates seamless interaction between students, parents, and teachers. This feature ensures that important updates and announcements are easily accessible to everyone involved.",
    },
    {
        id: 5,
        image: image13,
        title: "Resource Center",
        text: "Our online resource center provides students with access to educational materials, study guides, and additional learning resources, fostering a supportive environment for academic growth.",
    },
    {
        id: 6,
        image: image14,
        title: "Assessment Tools",
        text: "Our ERP system includes various assessment tools that allow teachers to create, manage, and analyze quizzes and tests, providing valuable insights into student learning and progress.",
    },
    {
        id: 7,
        image: image15,
        title: "Feedback System",
        text: "The feedback system encourages constructive communication between students and teachers, allowing for continuous improvement in the learning process. Students can receive personalized feedback on their assignments and projects.",
    },
    {
        id: 8,
        image: image16,
        title: "Parental Access",
        text: "Parents can monitor their child’s academic journey through dedicated access to reports, attendance, and communication tools, fostering a partnership between the school and families.",
    },
    {
        id: 9,
        image: image9,
        title: "Customizable Dashboard",
        text: "Our user-friendly dashboard allows students to customize their interface, displaying essential information like upcoming assignments, attendance records, and grades in a single view.",
    },
];



export const MoreInfo = [
    {
        id: 1,
        title: "Attendance Tracking",
        text: "Studee ERP simplifies attendance management, allowing teachers to easily record student attendance and monitor trends over time.",
    },
    {
        id: 2,
        title: "Homework Management",
        text: "Our platform enables teachers to assign, track, and review homework submissions, ensuring students stay on top of their learning tasks.",
    },
    {
        id: 3,
        title: "Progress Reports",
        text: "With Studee ERP, generating detailed progress reports is just a few clicks away, providing valuable insights into each student's academic journey.",
    },
    {
        id: 4,
        title: "Exam Scheduling",
        text: "Effortlessly schedule exams and notify students and teachers about important dates, ensuring seamless exam preparations.",
    },
    {
        id: 5,
        title: "Parental Involvement",
        text: "Keep parents informed about their child’s academic performance, attendance, and homework with real-time updates through Studee ERP.",
    },
];
