import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DashboardPage from '../../pages/tution/student/DashboardStudent';
import ExamDate from '../../pages/tution/student/ExamDate';
import Myprofile from '../../pages/tution/student/Myprofile';
import Syllabus from '../../pages/tution/student/syllabus';
// import AllDatesheets from '../../pages/tution/Teacher/mydatesheets';
// import AllSyllabus from '../../pages/tution/Teacher/mysyllabus';

const TeacherRoutes = () => (
    <Routes>
        <Route path="/tution_student-dashboard" element={<DashboardPage />} />
        <Route path="/tution_student-detail" element={<ExamDate />} />
        <Route path="/tution_student-syllabus-detail" element={<Syllabus />} />
        <Route path="/tution_student-profile" element={<Myprofile />} />

    </Routes>
);

export default TeacherRoutes;