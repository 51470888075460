// import React, { useEffect, useState } from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import Login from './pages/login/login';
// import TeacherSidebar from './sidebar/teacher';
// import StudentSidebar from './sidebar/student';
// import AdminSidebar from './sidebar/admin';
// import SuperAdminSidebar from './sidebar/superadmin';
// import Tution_teacher from './sidebar/tution/teacher';
// import Tution_student from './sidebar/tution/student';
// import TeacherHeader from './header/teacher/content';
// import StudentHeader from './header/student/content';
// import AdminHeader from './header/admin/content';
// import SuperAdminHeader from './header/superadmin/content';
// import TutionTeacherHeader from './header/tution/teacher/content';
// import TutionStudentHeader from './header/tution/student/content';
// import TeacherRoutes from './route/TeacherRoutes';
// import StudentRoutes from './route/StudentRoutes';
// import AdminRoutes from './route/AdminRoutes';
// import SuperAdminRoutes from './route/SuperAdminRoutes';
// import TutionTeacherRoutes from './route/tution/teacherRoute';
// import TutionStudentRoutes from './route/tution/studentRoute';
// import Footer from './pages/footer/footer';
// import './App.css';
// import Baseurl from './baseurl/baserurl';
// import About from './pages/login/homepage/new/routes/About';

// import Facility from './pages/login/homepage/new/routes/Facility';
// import ProgramLayout from './pages/login/homepage/new/layouts/ProgramLayout';
// import ProgramError from './pages/login/homepage/new/components/ProgramError';
// import UndergraduatePrograms from './pages/login/homepage/new/routes/UndergraduatePrograms';
// import ProgramDetails from './pages/login/homepage/new/routes/ProgramDetails';
// import Contact from './pages/login/homepage/new/routes/Contact';
// import Admission from './pages/login/homepage/new/routes/Admission';
// import Error from './pages/login/homepage/new/components/Error';
// // import UndergraduatePrograms, { programsLoader } from "./pages/login/homepage/new/routes/UndergraduatePrograms";
// // import ProgramDetails, { programDetailsLoader } from "./pages/login/homepage/new/routes/ProgramDetails";

// function App() {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const [role, setRole] = useState(null);

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     const userRole = localStorage.getItem('user');
//     if (token && userRole) {
//       const parsedUser = JSON.parse(userRole);
//       setIsAuthenticated(true);
//       setRole(parsedUser.role);
//     } else {
//       setIsAuthenticated(false);
//       setRole(null);
//     }
//   }, []);

//   const handleLogin = async (credentials) => {
//     try {
//       const response = await fetch(`${Baseurl.baseURL}api/auth/logi`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(credentials),
//       });
//       const data = await response.json();

//       if (data.token) {
//         localStorage.setItem('token', data.token);
//         localStorage.setItem('user', JSON.stringify(data.user));
//         setIsAuthenticated(true);
//         setRole(data.user.role);
//       } else {
//         console.error('Login failed, no token received.');
//       }
//     } catch (error) {
//       console.error('An error occurred:', error);
//     }
//   };

//   const renderSidebar = () => {
//     switch (role) {
//       case 'teacher': return <TeacherSidebar />;
//       case 'student': return <StudentSidebar />;
//       case 'admin': return <AdminSidebar />;
//       case 'superadmin': return <SuperAdminSidebar />;
//       case 'tution_teacher': return <Tution_teacher />;
//       case 'tution_student': return <Tution_student />;
//       default: return null;
//     }
//   };

//   const renderHeader = () => {
//     switch (role) {
//       case 'teacher': return <TeacherHeader />;
//       case 'student': return <StudentHeader />;
//       case 'admin': return <AdminHeader />;
//       case 'superadmin': return <SuperAdminHeader />;
//       case 'tution_teacher': return <TutionTeacherHeader />;
//       case 'tution_student': return <TutionStudentHeader />;
//       default: return null;
//     }
//   };

//   const renderRoutes = () => {
//     switch (role) {
//       case 'teacher': return <TeacherRoutes />;
//       case 'student': return <StudentRoutes />;
//       case 'admin': return <AdminRoutes />;
//       case 'superadmin': return <SuperAdminRoutes />;
//       case 'tution_teacher': return <TutionTeacherRoutes />;
//       case 'tution_student': return <TutionStudentRoutes />;
//       default: return null;
//     }
//   };

//   return (
//     <Router>
//       <div className="dashboard">
//         {isAuthenticated && renderSidebar()}
//         <div className="dashboard--content">
//           {isAuthenticated && renderHeader()}
//           <div className="route">
//             <Routes>
//               <Route
//                 path="/"
//                 element={
//                   isAuthenticated ? <Navigate to={"/"} /> : <Login onLogin={handleLogin} />
//                 }
//               />
//               {isAuthenticated ? (
//                 <Route path="/*" element={renderRoutes()} />
//               ) : (
//                 <Route path="/" element={<Navigate to="/" />} />
//               )}
//               <Route path="/about" element={<About />} />
//               <Route path="/facility" element={<Facility />} />
//               <Route path="/contact" element={<Contact />} />
//               <Route path="/apply" element={<Admission />} />
//               <Route path="/*" element={<Error />} />
//             </Routes>
//           </div>
//           {isAuthenticated && <Footer />} 
//         </div>
//       </div>
//     </Router>
//   );
// }

// export default App;

// import React, { useEffect, useState } from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import Login from './pages/login/login';
// import TeacherSidebar from './sidebar/teacher';
// import StudentSidebar from './sidebar/student';
// import AdminSidebar from './sidebar/admin';
// import SuperAdminSidebar from './sidebar/superadmin';
// import Tution_teacher from './sidebar/tution/teacher';
// import Tution_student from './sidebar/tution/student';
// import TeacherHeader from './header/teacher/content';
// import StudentHeader from './header/student/content';
// import AdminHeader from './header/admin/content';
// import SuperAdminHeader from './header/superadmin/content';
// import TutionTeacherHeader from './header/tution/teacher/content';
// import TutionStudentHeader from './header/tution/student/content';
// import TeacherRoutes from './route/TeacherRoutes';
// import StudentRoutes from './route/StudentRoutes';
// import AdminRoutes from './route/AdminRoutes';
// import SuperAdminRoutes from './route/SuperAdminRoutes';
// import TutionTeacherRoutes from './route/tution/teacherRoute';
// import TutionStudentRoutes from './route/tution/studentRoute';
// import Footer from './pages/footer/footer';
// import './App.css';
// import Baseurl from './baseurl/baserurl';
// import About from './pages/login/homepage/new/routes/About';
// import Facility from './pages/login/homepage/new/routes/Facility';
// import Contact from './pages/login/homepage/new/routes/Contact';
// import Admission from './pages/login/homepage/new/routes/Admission';
// import Error from './pages/login/homepage/new/components/Error';

// function App() {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const [role, setRole] = useState(null);

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     const userRole = localStorage.getItem('user');
//     if (token && userRole) {
//       const parsedUser = JSON.parse(userRole);
//       setIsAuthenticated(true);
//       setRole(parsedUser.role);
//     } else {
//       setIsAuthenticated(false);
//       setRole(null);
//     }
//   }, []);

//   const handleLogin = async (credentials) => {
//     try {
//       const response = await fetch(`${Baseurl.baseURL}api/auth/logi`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(credentials),
//       });
//       const data = await response.json();

//       if (data.token) {
//         localStorage.setItem('token', data.token);
//         localStorage.setItem('user', JSON.stringify(data.user));
//         setIsAuthenticated(true);
//         setRole(data.user.role);
//       } else {
//         console.error('Login failed, no token received.');
//       }
//     } catch (error) {
//       console.error('An error occurred:', error);
//     }
//   };

//   const renderSidebar = () => {
//     switch (role) {
//       case 'teacher': return <TeacherSidebar />;
//       case 'student': return <StudentSidebar />;
//       case 'admin': return <AdminSidebar />;
//       case 'superadmin': return <SuperAdminSidebar />;
//       case 'tution_teacher': return <Tution_teacher />;
//       case 'tution_student': return <Tution_student />;
//       default: return null;
//     }
//   };

//   const renderHeader = () => {
//     switch (role) {
//       case 'teacher': return <TeacherHeader />;
//       case 'student': return <StudentHeader />;
//       case 'admin': return <AdminHeader />;
//       case 'superadmin': return <SuperAdminHeader />;
//       case 'tution_teacher': return <TutionTeacherHeader />;
//       case 'tution_student': return <TutionStudentHeader />;
//       default: return null;
//     }
//   };

//   const renderRoutes = () => {
//     switch (role) {
//       case 'teacher': return <TeacherRoutes />;
//       case 'student': return <StudentRoutes />;
//       case 'admin': return <AdminRoutes />;
//       case 'superadmin': return <SuperAdminRoutes />;
//       case 'tution_teacher': return <TutionTeacherRoutes />;
//       case 'tution_student': return <TutionStudentRoutes />;
//       default: return null;
//     }
//   };

//   return (
//     <Router>
//       <div className="dashboard">
//         {isAuthenticated && renderSidebar()}
//         <div className="dashboard--content">
//           {isAuthenticated && renderHeader()}
//           <div className="route">
//             <Routes>
//               <Route
//                 path="/"
//                 element={
//                   isAuthenticated ? <Navigate to="/" /> : <Login onLogin={handleLogin} />
//                 }
//               />
//               {isAuthenticated ? (
//                 <Route path="/*" element={renderRoutes()} />
//               ) : (
//                 <Route path="/" element={<Navigate to="/" />} />
//               )}
//               {/* Public Routes */}
//               <Route path="/login" element={<Login />} />
//               <Route path="/about" element={<About />} />
//               <Route path="/facility" element={<Facility />} />
//               <Route path="/contact" element={<Contact />} />
//               <Route path="/apply" element={<Admission />} />
//               <Route path="/*" element={<Error />} />
//             </Routes>
//           </div>
//         </div>
//       </div>
//     </Router>
//   );
// }

// export default App;

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Login from './pages/login/login';
import TeacherSidebar from './sidebar/teacher';
import StudentSidebar from './sidebar/student';
import AdminSidebar from './sidebar/admin';
import SuperAdminSidebar from './sidebar/superadmin';
import Tution_teacher from './sidebar/tution/teacher';
import Tution_student from './sidebar/tution/student';
import TeacherHeader from './header/teacher/content';
import StudentHeader from './header/student/content';
import AdminHeader from './header/admin/content';
import SuperAdminHeader from './header/superadmin/content';
import TutionTeacherHeader from './header/tution/teacher/content';
import TutionStudentHeader from './header/tution/student/content';
import TeacherRoutes from './route/TeacherRoutes';
import StudentRoutes from './route/StudentRoutes';
import AdminRoutes from './route/AdminRoutes';
import SuperAdminRoutes from './route/SuperAdminRoutes';
import TutionTeacherRoutes from './route/tution/teacherRoute';
import TutionStudentRoutes from './route/tution/studentRoute';
import Footer from './pages/footer/footer';
import './App.css';
import Baseurl from './baseurl/baserurl';
import About from './pages/login/homepage/new/routes/About';
import Facility from './pages/login/homepage/new/routes/Facility';
import Contact from './pages/login/homepage/new/routes/Contact';
import Admission from './pages/login/homepage/new/routes/Admission';
import Error from './pages/login/homepage/new/components/Error';
import PrivacyPolicy from './pages/pages/privacy_policy';
import TermsAndConditions from './pages/pages/Terms_and_Conditions';
import CancellationAndRefund from './pages/pages/Cancellation_and_Refund';
import ShippingAndDelivery from './pages/pages/Shipping_and_Delivery';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState(null);
  // const navigate = useNavigate(); // Create a navigate function
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const userRole = localStorage.getItem('user');
    if (token && userRole) {
      const parsedUser = JSON.parse(userRole);
      setIsAuthenticated(true);
      setRole(parsedUser.role);
    } else {
      setIsAuthenticated(false);
      setRole(null);
    }
  }, []);

  const handleLogin = async (credentials) => {
    try {
      const response = await fetch(`${Baseurl.baseURL}api/auth/logi`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });
      const data = await response.json();

      if (data.token) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('user', JSON.stringify(data.user));
        setIsAuthenticated(true);
        setRole(data.user.role);
      } else {
        console.error('Login failed, no token received.');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const renderSidebar = () => {
    switch (role) {
      case 'teacher': return <TeacherSidebar />;
      case 'student': return <StudentSidebar />;
      case 'admin': return <AdminSidebar />;
      case 'superadmin': return <SuperAdminSidebar />;
      case 'tution_teacher': return <Tution_teacher />;
      case 'tution_student': return <Tution_student />;
      default: return null;
    }
  };

  const renderHeader = () => {
    switch (role) {
      case 'teacher': return <TeacherHeader />;
      case 'student': return <StudentHeader />;
      case 'admin': return <AdminHeader />;
      case 'superadmin': return <SuperAdminHeader />;
      case 'tution_teacher': return <TutionTeacherHeader />;
      case 'tution_student': return <TutionStudentHeader />;
      default: return null;
    }
  };

  const renderRoutes = () => {
    switch (role) {
      case 'teacher': return <TeacherRoutes />;
      case 'student': return <StudentRoutes />;
      case 'admin': return <AdminRoutes />;
      case 'superadmin': return <SuperAdminRoutes />;
      case 'tution_teacher': return <TutionTeacherRoutes />;
      case 'tution_student': return <TutionStudentRoutes />;
      default: return null;
    }
  };

  // Listen for route changes and reload the page
  useEffect(() => {
    if (isAuthenticated && location.pathname === '/') {
      window.location.reload();
    }
  }, [role, isAuthenticated, location.pathname]);

  return (
    <div className="dashboard">
      {isAuthenticated && renderSidebar()}
      <div className="dashboard--content">
        {isAuthenticated && renderHeader()}
        <div className="route">
          <Routes>
            <Route
              path="/"
              element={
                isAuthenticated ? <Navigate to="/" /> : <Login onLogin={handleLogin} />
              }
            />
            {isAuthenticated ? (
              <Route path="/*" element={renderRoutes()} />
            ) : (
              <Route path="/" element={<Navigate to="/" />} />
            )}
            {/* Public Routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/about" element={<About />} />
            <Route path="/facility" element={<Facility />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/apply" element={<Admission />} />

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/cancellation-and-refund" element={<CancellationAndRefund />} />
            <Route path="/shipping-and-delivery" element={<ShippingAndDelivery />} />
            {/* <Route path="/contact-us" element={<ContactUs />} /> */}

            <Route path="/*" element={<Error />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default function WrappedApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}
