// import React from 'react';
// import { Route, Router, Routes } from 'react-router-dom';
// import DashboardPage from '../pages/teacher/DashboardPage';
// import TeacherDetailPage from '../pages/teacher/TeacherDetailPage';
// import TeacherDetailEditPage from '../pages/teacher/TeacherDetailEditPage';

// const TeacherRoutes = () => (
//     <>
//         <Router>
//             <Routes>
//                 <Route path="/teacher-dashboard" element={<DashboardPage />} />
//                 <Route path="/teacher-detail" element={<TeacherDetailPage />} />
//                 <Route path="/teacher-detail-edit" element={<TeacherDetailEditPage />} />
//             </Routes>
//         </Router>

//     </>
// );

// export default TeacherRoutes;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DashboardPage from '../pages/teacher/DashboardPage';
import TeacherDetailPage from '../pages/teacher/TeacherDetailPage';
import TeacherDetailEditPage from '../pages/teacher/TeacherAddStudent';
import Attendance from '../pages/teacher/attendenace';
import AddResult from '../pages/teacher/addresult';
import Application from "../pages/teacher/application/myapplication";
import Feedback from "../pages/teacher/application/feedback";
import Complain from "../pages/teacher/application/complant";

const TeacherRoutes = () => (
    <Routes>
        <Route path="/teacher-dashboard" element={<DashboardPage />} />
        <Route path="/attendance-dashboard" element={<Attendance />} />
        <Route path="/teacher-detail" element={<TeacherDetailPage />} />
        <Route path="/teacher-detail-edit" element={<TeacherDetailEditPage />} />
        <Route path="/result-dashboard" element={<AddResult />} />
        <Route path="/teacher-application" element={<Application />} />
        <Route path="/teacher-feedback" element={<Feedback />} />
        <Route path="/teacher-complain" element={<Complain />} />

    </Routes>
);

export default TeacherRoutes;