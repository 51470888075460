// import React, { useState, useEffect } from 'react';
// import { styled } from '@mui/material/styles';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell, { tableCellClasses } from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import Button from '@mui/material/Button';
// import Checkbox from '@mui/material/Checkbox';
// import { getAuthToken } from '../../../auth_token/authUtils';
// import { TextField, Stack, Typography, Switch } from '@mui/material';
// import Alert from '@mui/material/Alert';
// import CircularProgress from '@mui/material/CircularProgress';
// import { Snackbar } from '@mui/material';
// import Baseurl from '../../../baseurl/baserurl';

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: theme.palette.common.black,
//     color: theme.palette.common.white,
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//   },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   '&:nth-of-type(odd)': {
//     backgroundColor: theme.palette.action.hover,
//   },
// }));

// const ButtonContainer = styled('div')(({ theme }) => ({
//   position: 'relative',
//   display: 'inline-block',
// }));

// const Loader = styled(CircularProgress)(({ theme }) => ({
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
// }));

// const AntSwitch = styled(Switch)(({ theme }) => ({
//   width: 28,
//   height: 16,
//   padding: 0,
//   display: 'flex',
//   '& .MuiSwitch-switchBase': {
//     padding: 2,
//     '&.Mui-checked': {
//       transform: 'translateX(12px)',
//       color: '#fff',
//       '& + .MuiSwitch-track': {
//         opacity: 1,
//         backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
//       },
//     },
//   },
//   '& .MuiSwitch-thumb': {
//     boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
//     width: 12,
//     height: 12,
//     borderRadius: 6,
//     transition: theme.transitions.create(['width'], {
//       duration: 200,
//     }),
//   },
//   '& .MuiSwitch-track': {
//     borderRadius: 16 / 2,
//     opacity: 1,
//     backgroundColor:
//       theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
//     boxSizing: 'border-box',
//   },
// }));

// export default function AttendanceTable() {
//   const [schools, setSchools] = useState([]);
//   const [attendance, setAttendance] = useState({});
//   const [homeworkStatus, setHomeworkStatus] = useState({});
//   const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
//   const [isSubmitted, setIsSubmitted] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [alertOpen, setAlertOpen] = useState(false);
//   const [alertType, setAlertType] = useState('success');
//   const [alertMessage, setAlertMessage] = useState('');


//   useEffect(() => {
//     fetchSchools();
//     checkIfAlreadySubmitted();
//   }, [date]);

//   const fetchSchools = async () => {
//     const token = getAuthToken();

//     if (!token) {
//       handleAlert('error', 'Sorry, you are not authenticated. Please re-login.');
//       return (window.location.href = '/');
//     }

//     const user = JSON.parse(localStorage.getItem('user'));
//     const school_id = user?.school_id;

//     try {
//       const response = await fetch(`${Baseurl.baseURL}api/tutionstudentprofile/show_Tution_Student_profile`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({
//           school_id: school_id,
//         }),
//       });

//       const data = await response.json();
//       if (response.ok) {
//         if (Array.isArray(data) && data.length > 0) {
//           setSchools(data);
//         } else {
//           handleAlert('warning', 'No data found.');
//         }
//       } else {
//         handleAlert('error', `Error fetching schools: ${data.message}`);
//       }
//     } catch (error) {
//       handleAlert('error', 'An error occurred. Please try again.');
//     }
//   };

//   const checkIfAlreadySubmitted = async () => {
//     const token = getAuthToken();

//     if (!token) {
//       handleAlert('error', 'Sorry, you are not authenticated. Please re-login.');
//       return (window.location.href = '/');
//     }

//     const user = JSON.parse(localStorage.getItem('user'));

//     try {
//       const response = await fetch(`${Baseurl.baseURL}api/tution_attendance/checkSubmission`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({
//           school_id: user.school_id,
//           date: date,
//         }),
//       });

//       const data = await response.json();
//       if (response.ok) {
//         setIsSubmitted(data.isSubmitted);
//         handleAlert('success', 'Attendance already submitted.');
//       } else {
//         handleAlert('error', `Error checking submission: ${data.message}`);
//       }
//     } catch (error) {
//       handleAlert('error', 'An error occurred. Please try again.');
//     }
//   };

//   const handleAttendanceChange = (student_id, isPresent) => {
//     setAttendance((prev) => ({
//       ...prev,
//       [student_id]: isPresent,
//     }));
//   };

//   const handleHomeworkChange = (student_id, status) => {
//     setHomeworkStatus((prev) => ({
//       ...prev,
//       [student_id]: status,
//     }));
//   };

//   const handleSubmitAttendance = async () => {
//     const token = getAuthToken();

//     if (!token) {
//       handleAlert('error', 'Sorry, you are not authenticated. Please re-login.');
//       return (window.location.href = '/');
//     }

//     const user = JSON.parse(localStorage.getItem('user'));

//     const attendanceData = schools.map((student) => ({
//       school_id: user.school_id,
//       student_id: student.student_id,
//       student_name: student.student_name,
//       status: attendance[student.student_id] ? 'Present' : 'Absent',
//       homework_status: homeworkStatus[student.student_id] ? 'Yes' : 'No',
//       student_email: student.student_email,
//     }));

//     setIsLoading(true);

//     try {
//       const response = await fetch(`${Baseurl.baseURL}api/tution_attendance/submitAttendence`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({ attendanceData, date }),
//       });

//       if (response.ok) {
//         handleAlert('success', 'Attendance submitted successfully');
//         resetForm();
//       } else {
//         const error = await response.json();
//         handleAlert('error', `Error submitting attendance: ${error.message}`);
//       }
//     } catch (error) {
//       handleAlert('error', 'An error occurred. Please try again.');
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const resetForm = () => {
//     setAttendance({});
//     setHomeworkStatus({});
//     setSchools([]);
//     setIsSubmitted(true);
//     setDate(new Date().toISOString().slice(0, 10));
//   };

//   const handleAlert = (type, message) => {
//     setAlertType(type);
//     setAlertMessage(message);
//     setAlertOpen(true);
//   };

//   const handleAlertClose = () => {
//     setAlertOpen(false);
//   };

//   return (
//     <>
//       <TextField
//         type="date"
//         value={date}
//         onChange={(e) => setDate(e.target.value)}
//         fullWidth
//         margin="normal"
//       />
//       <TableContainer component={Paper}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               {/* <StyledTableCell>Student ID</StyledTableCell> */}
//               <StyledTableCell>Student Name</StyledTableCell>
//               <StyledTableCell>Present</StyledTableCell>
//               <StyledTableCell>Absent</StyledTableCell>
//               <StyledTableCell>Homework</StyledTableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {schools.map((student) => (
//               <StyledTableRow key={student.student_id}>
//                 {/* <StyledTableCell>{student.student_id}</StyledTableCell> */}
//                 <StyledTableCell>{student.student_name}</StyledTableCell>
//                 <StyledTableCell>
//                   <Checkbox
//                     checked={attendance[student.student_id] === true}
//                     onChange={() => handleAttendanceChange(student.student_id, true)}
//                     disabled={isSubmitted || isLoading}
//                   />
//                 </StyledTableCell>
//                 <StyledTableCell>
//                   <Checkbox
//                     checked={attendance[student.student_id] === false}
//                     onChange={() => handleAttendanceChange(student.student_id, false)}
//                     disabled={isSubmitted || isLoading}
//                   />
//                 </StyledTableCell>
//                 <StyledTableCell>
//                   <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
//                     <Typography>No</Typography>
//                     <AntSwitch
//                       checked={homeworkStatus[student.student_id] === true}
//                       onChange={() => handleHomeworkChange(student.student_id, !homeworkStatus[student.student_id])}
//                       disabled={isSubmitted || isLoading}
//                     />
//                     <Typography>Yes</Typography>
//                   </Stack>
//                 </StyledTableCell>
//               </StyledTableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//       <ButtonContainer>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={handleSubmitAttendance}
//           disabled={isSubmitted || isLoading}
//         >
//           Submit Attendance
//         </Button>
//         {isLoading && <Loader size={20} />}
//       </ButtonContainer>

//       <Snackbar
//         open={alertOpen}
//         autoHideDuration={3000}
//         onClose={handleAlertClose}
//         anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
//       >
//         <Alert onClose={handleAlertClose} severity={alertType}>
//           {alertMessage}
//         </Alert>
//       </Snackbar>
//     </>
//   );
// }


import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { getAuthToken } from '../../../auth_token/authUtils';
import { TextField, Stack, Typography, Switch, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { Snackbar } from '@mui/material';
import Baseurl from '../../../baseurl/baserurl';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ButtonContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'inline-block',
}));

const Loader = styled(CircularProgress)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

export default function AttendanceTable() {
  const [schools, setSchools] = useState([]);
  const [attendance, setAttendance] = useState({});
  const [homeworkStatus, setHomeworkStatus] = useState({});
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [selectedValue, setSelectedValue] = useState('1');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    fetchSchools();
    checkIfAlreadySubmitted();
  }, [date, selectedValue]); // Include selectedValue in dependency array

  const fetchSchools = async () => {
    const token = getAuthToken();

    if (!token) {
      handleAlert('error', 'Sorry, you are not authenticated. Please re-login.');
      return (window.location.href = '/');
    }

    const user = JSON.parse(localStorage.getItem('user'));
    const school_id = user?.school_id;

    try {
      const response = await fetch(`${Baseurl.baseURL}api/tutionstudentprofile/show_Tution_Student_profile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          school_id: school_id,
          value: selectedValue, // Include selected value in payload
        }),
      });

      const data = await response.json();
      if (response.ok) {
        if (Array.isArray(data) && data.length > 0) {
          setSchools(data);

        } else {
          setSchools([]);
          handleAlert('warning', 'No data found.');
        }
      } else {
        setSchools([]);
        handleAlert('error', `${data.message}`);
      }
    } catch (error) {
      setSchools([]);
      handleAlert('error', 'An error occurred. Please try again.');
    }
  };

  const checkIfAlreadySubmitted = async () => {
    const token = getAuthToken();

    if (!token) {
      handleAlert('error', 'Sorry, you are not authenticated. Please re-login.');
      return (window.location.href = '/');
    }

    const user = JSON.parse(localStorage.getItem('user'));

    try {
      const response = await fetch(`${Baseurl.baseURL}api/tution_attendance/checkSubmission`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          school_id: user.school_id,
          date: date,
          batch: selectedValue
        }),
      });

      const data = await response.json();
      if (response.ok) {
        setIsSubmitted(data.isSubmitted);
        // handleAlert('success', 'Attendance already submitted.');
      } else {
        handleAlert('error', `Error checking submission: ${data.message}`);
      }
    } catch (error) {
      handleAlert('error', 'An error occurred. Please try again.');
    }
  };

  const handleAttendanceChange = (student_id, isPresent) => {
    setAttendance((prev) => ({
      ...prev,
      [student_id]: isPresent,
    }));
  };

  const handleHomeworkChange = (student_id, status) => {
    setHomeworkStatus((prev) => ({
      ...prev,
      [student_id]: status,
    }));
  };

  const handleSubmitAttendance = async () => {
    const token = getAuthToken();

    if (!token) {
      handleAlert('error', 'Sorry, you are not authenticated. Please re-login.');
      return (window.location.href = '/');
    }

    const user = JSON.parse(localStorage.getItem('user'));

    const attendanceData = schools.map((student) => ({
      school_id: user.school_id,
      student_id: student.student_id,
      student_name: student.student_name,
      status: attendance[student.student_id] ? 'Present' : 'Absent',
      homework_status: homeworkStatus[student.student_id] ? 'Yes' : 'No',
      student_email: student.student_email,
      batch: student.batch
    }));

    setIsLoading(true);

    try {
      const response = await fetch(`${Baseurl.baseURL}api/tution_attendance/submitAttendence`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ attendanceData, date, }),
      });

      if (response.ok) {
        handleAlert('success', 'Attendance submitted successfully');
        resetForm();
      } else {
        const error = await response.json();
        handleAlert('error', `Error submitting attendance: ${error.message}`);
      }
    } catch (error) {
      handleAlert('error', 'An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setAttendance({});
    setHomeworkStatus({});
    setSchools([]);
    setIsSubmitted(true);
    setDate(new Date().toISOString().slice(0, 10));
    setSelectedValue('1'); // Reset selected value
  };

  const handleAlert = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <>
      <TextField
        type="date"
        value={date}
        onChange={(e) => setDate(e.target.value)}
        fullWidth
        margin="normal"
      />
      <FormControl fullWidth margin="normal">
        <InputLabel>Select Value</InputLabel>
        <Select
          value={selectedValue}
          onChange={(e) => setSelectedValue(e.target.value)}
        >
          <MenuItem value="1">Batch-1</MenuItem>
          <MenuItem value="2">Batch-2</MenuItem>
          <MenuItem value="3">Batch-3</MenuItem>
          <MenuItem value="4">Batch-4</MenuItem>
          <MenuItem value="5">Batch-5</MenuItem>
        </Select>
        {/* <Typography>Batch-{selectedValue}</Typography> */}
      </FormControl>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Student Name</StyledTableCell>
              <StyledTableCell>Present</StyledTableCell>
              <StyledTableCell>Absent</StyledTableCell>
              <StyledTableCell>Homework</StyledTableCell>
            </TableRow>
          </TableHead>
          {/* <TableBody>
            {schools.map((student) => (
              <StyledTableRow key={student.student_id}>
                <StyledTableCell>{student.student_name}</StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    checked={attendance[student.student_id] === true}
                    onChange={() => handleAttendanceChange(student.student_id, true)}
                    disabled={isSubmitted || isLoading}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    checked={attendance[student.student_id] === false}
                    onChange={() => handleAttendanceChange(student.student_id, false)}
                    disabled={isSubmitted || isLoading}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                    <Typography>No</Typography>
                    <AntSwitch
                      checked={homeworkStatus[student.student_id] === true}
                      onChange={() => handleHomeworkChange(student.student_id, !homeworkStatus[student.student_id])}
                      disabled={isSubmitted || isLoading}
                    />
                    <Typography>Yes</Typography>
                  </Stack>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody> */}
          <TableBody>
            {schools.length > 0 ? (
              schools.map((student) => (
                <StyledTableRow key={student.student_id}>
                  <StyledTableCell>{student.student_name}</StyledTableCell>
                  <StyledTableCell>
                    <Checkbox
                      checked={attendance[student.student_id] === true}
                      onChange={() => handleAttendanceChange(student.student_id, true)}
                      disabled={isSubmitted || isLoading}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Checkbox
                      checked={attendance[student.student_id] === false}
                      onChange={() => handleAttendanceChange(student.student_id, false)}
                      disabled={isSubmitted || isLoading}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                      <Typography>No</Typography>
                      <AntSwitch
                        checked={homeworkStatus[student.student_id] === true}
                        onChange={() => handleHomeworkChange(student.student_id, !homeworkStatus[student.student_id])}
                        disabled={isSubmitted || isLoading}
                      />
                      <Typography>Yes</Typography>
                    </Stack>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell colSpan={4} align="center">
                  No student found
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>

        </Table>
      </TableContainer>
      <ButtonContainer>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmitAttendance}
          disabled={isSubmitted || isLoading}
        >
          Submit Attendance
        </Button>
        {isLoading && <Loader size={20} />}
      </ButtonContainer>

      <Snackbar
        open={alertOpen}
        autoHideDuration={3000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleAlertClose} severity={alertType}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
