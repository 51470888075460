// import React from 'react';
// import { Route, Routes } from 'react-router-dom';
// import DashboardPage from '../../pages/tution/Teacher/DashboardTeacher';
// import AllStudentDatail from '../../pages/tution/Teacher/allstudent';
// import Add_edit_student from '../../pages/tution/Teacher/addeditstudent';
// import AllClasses from '../../pages/tution/Teacher/myclasses';
// import AllDatesheets from '../../pages/tution/Teacher/mydatesheets';
// import AllSyllabus from '../../pages/tution/Teacher/mysyllabus';
// import MyNotepaid from '../../pages/tution/Teacher/mynotepade';
// import NoteDetail from '../../pages/tution/Teacher/mynotepaid_brief';

// const TeacherRoutes = () => (
//     <Routes>
//         <Route path="/tution_teacher-dashboard" element={<DashboardPage />} />
//         <Route path="/tution-all-student-detail" element={<AllStudentDatail />} />
//         <Route path="/tution_teacher-add-edit-student" element={<Add_edit_student />} />
//         <Route path="/tution-all-class-detail" element={<AllClasses />} />
//         <Route path="/tution-all-datesheet-detail" element={<AllDatesheets />} />
//         <Route path="/tution-all-syllabus-detail" element={<AllSyllabus />} />
//         <Route path="/tution-my-notepaid" element={<MyNotepaid />} />
//         <Route path="/note/:id" element={<NoteDetail />} />

//     </Routes>
// );

// export default TeacherRoutes;
 

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../ProtectedRoute'; // Import the ProtectedRoute component
import DashboardPage from '../../pages/tution/Teacher/DashboardTeacher';
import AllStudentDatail from '../../pages/tution/Teacher/allstudent';
import Add_edit_student from '../../pages/tution/Teacher/addeditstudent';
import AllClasses from '../../pages/tution/Teacher/myclasses';
import AllDatesheets from '../../pages/tution/Teacher/mydatesheets';
import AllSyllabus from '../../pages/tution/Teacher/mysyllabus';
import MyNotepaid from '../../pages/tution/Teacher/mynotepade';
import NoteDetail from '../../pages/tution/Teacher/mynotepaid_brief';
import Login from '../../pages/login/login'; // Assuming you have a login page component

const TeacherRoutes = () => (
    <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/tution_teacher-dashboard" element={<ProtectedRoute element={DashboardPage} />} />
        <Route path="/tution-all-student-detail" element={<ProtectedRoute element={AllStudentDatail} />} />
        <Route path="/tution_teacher-add-edit-student" element={<ProtectedRoute element={Add_edit_student} />} />
        <Route path="/tution-all-class-detail" element={<ProtectedRoute element={AllClasses} />} />
        <Route path="/tution-all-datesheet-detail" element={<ProtectedRoute element={AllDatesheets} />} />
        <Route path="/tution-all-syllabus-detail" element={<ProtectedRoute element={AllSyllabus} />} />
        <Route path="/tution-my-notepaid" element={<ProtectedRoute element={MyNotepaid} />} />
        <Route path="/note/:id" element={<ProtectedRoute element={NoteDetail} />} />
    </Routes>
);

export default TeacherRoutes;
