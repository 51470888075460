import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { getAuthToken } from '../../auth_token/authUtils';
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Baseurl from '../../baseurl/baserurl';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export default function CustomizedTables() {
  const [open, setOpen] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [name, setName] = React.useState('');
  const [school_Name, setSchoolName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [role, setRole] = React.useState('');
  const [mobile, setSchoolContact] = React.useState('');
  const [schools, setSchools] = React.useState([]);
  const [selectedSchool, setSelectedSchool] = React.useState(null);
  const [user_limit, setUser_limit] = React.useState(null);


  React.useEffect(() => {
    fetchSchools();
  }, []);

  const fetchSchools = async () => {
    const token = getAuthToken();

    if (!token) {
      alert('Sorry Your are not authenticated. Please re-login.');
      window.location.href = '/'; // Redirect to home page
      window.location.reload(); // Force reload the page
      return;
    }


    try {
      const response = await fetch(`${Baseurl.baseURL}api/addNewSchool/show_schools`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setSchools(data);
      } else {
        const error = await response.json();
        alert('Error fetching schools: ' + error.message);
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenEditModal(false); // Close the edit modal as well
  };

  const handleSubmit = async () => {
    const token = getAuthToken();

    if (!token) {
      alert('Sorry Your are not authenticated. Please re-login.');
      window.location.href = '/'; // Redirect to home page
      window.location.reload(); // Force reload the page
      return;
    }


    try {
      const response = await fetch(`${Baseurl.baseURL}api/addNewSchool/schools`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          school_Name: school_Name,
          email: email,
          password: password,
          mobile: mobile,
          role: role,
        }),
      });

      if (response.ok) {
        fetchSchools(); // Refresh the table
        handleClose();
      } else {
        const error = await response.json();
        alert('Error adding school: ' + error.message);
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };

  const handleEditOpen = (school) => {
    setSelectedSchool(school);
    setSchoolName(school.school_name);
    setSchoolName(school.name);
    setEmail(school.email);
    setPassword(school.password);
    setSchoolContact(school.mobile);
    setRole(school.role);
    setUser_limit(school.user_limit);
    setOpenEditModal(true);
  };

  const handleEditSubmit = async () => {
    const token = getAuthToken();

    if (!token) {
      alert('Sorry Your are not authenticated. Please re-login.');
      window.location.href = '/'; // Redirect to home page
      window.location.reload(); // Force reload the page
      return;
    }


    try {
      const response = await fetch(`${Baseurl.baseURL}api/addNewSchool/schools/${selectedSchool.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          name: name,
          school_Name: school_Name,
          email: email,
          password: password,
          mobile: mobile,
          role: role,
          user_limit: user_limit,
        }),
      });

      if (response.ok) {
        fetchSchools(); // Refresh the table after editing
        handleClose();
      } else {
        const error = await response.json();
        alert('Error updating school: ' + error.message);
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };

  const handleDelete = async (id) => {
    const token = getAuthToken();

    if (!token) {
      alert('Sorry Your are not authenticated. Please re-login.');
      window.location.href = '/'; // Redirect to home page
      window.location.reload(); // Force reload the page
      return;
    }


    try {
      const response = await fetch(`${Baseurl.baseURL}api/addNewSchool/schools/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        fetchSchools(); // Refresh the list after deletion
      } else {
        alert('Error deleting school.');
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <>
      <Grid sx={{ m: 3 }}>
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Add New School
        </Button>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Add New School</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="schoolName"
              label="School Name"
              type="text"
              fullWidth
              value={school_Name}
              onChange={(e) => setSchoolName(e.target.value)}
            />
            <TextField
              margin="dense"
              id="email"
              label="Email"
              type="email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="dense"
              id="schoolContact"
              label="School Contact"
              type="text"
              fullWidth
              value={mobile}
              onChange={(e) => setSchoolContact(e.target.value)}
            />
            <TextField
              margin="dense"
              id="password"
              label="Password"
              type="password"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormControl fullWidth margin="dense">
              <InputLabel>Role</InputLabel>
              <Select value={role} onChange={(e) => setRole(e.target.value)}>
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="student">Student</MenuItem>
                <MenuItem value="teacher">Teacher</MenuItem>
                <MenuItem value="tution_teacher">Tution Teacher</MenuItem>
                <MenuItem value="tution_student">Tution Student</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">Cancel</Button>
            <Button onClick={handleSubmit} color="primary">Add School</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openEditModal} onClose={handleClose}>
          <DialogTitle>Edit School</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="text"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="schoolName"
              label="School Name"
              type="text"
              fullWidth
              value={school_Name}
              onChange={(e) => setSchoolName(e.target.value)}
            />
            <TextField
              margin="dense"
              id="email"
              label="Email"
              type="email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="dense"
              id="schoolContact"
              label="School Contact"
              type="text"
              fullWidth
              value={mobile}
              onChange={(e) => setSchoolContact(e.target.value)}
            />
            <TextField
              margin="dense"
              id="password"
              label="Password"
              type="password"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              margin="dense"
              id="user_limit"
              label="User Limit"
              type="text"
              fullWidth
              value={user_limit}
              onChange={(e) => setUser_limit(e.target.value)}
            />
            <FormControl fullWidth margin="dense">
              <InputLabel>Role</InputLabel>
              <Select value={role} onChange={(e) => setRole(e.target.value)}>
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="student">Student</MenuItem>
                <MenuItem value="teacher">Teacher</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">Cancel</Button>
            <Button onClick={handleEditSubmit} color="primary">Update School</Button>
          </DialogActions>
        </Dialog>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>ID</StyledTableCell>
                <StyledTableCell>Unique ID</StyledTableCell>
                <StyledTableCell>School ID</StyledTableCell>
                <StyledTableCell>Teacher ID</StyledTableCell>
                <StyledTableCell>Student ID</StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>School Name</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Password</StyledTableCell>
                <StyledTableCell>School Contact</StyledTableCell>
                <StyledTableCell>Role</StyledTableCell>
                <StyledTableCell>Role ID</StyledTableCell>
                <StyledTableCell>User Limit</StyledTableCell>
                <StyledTableCell>Edit</StyledTableCell>
                <StyledTableCell>Delete</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schools.map((school) => (
                <StyledTableRow key={school.id}>
                  <StyledTableCell component="th" scope="row">
                    {school.id}
                  </StyledTableCell>
                  <StyledTableCell>{school.unique_id}</StyledTableCell>
                  <StyledTableCell>{school.school_id}</StyledTableCell>
                  <StyledTableCell>{school.teacher_id}</StyledTableCell>
                  <StyledTableCell>{school.student_id}</StyledTableCell>
                  <StyledTableCell>{school.name}</StyledTableCell>
                  <StyledTableCell>{school.school_name}</StyledTableCell>
                  <StyledTableCell>{school.email}</StyledTableCell>
                  <StyledTableCell>{school.password}</StyledTableCell>
                  <StyledTableCell>{school.school_contact}</StyledTableCell>
                  <StyledTableCell>{school.role}</StyledTableCell>
                  <StyledTableCell>{school.role_id}</StyledTableCell>
                  <StyledTableCell>{school.user_limit}</StyledTableCell>
                  <StyledTableCell>
                    <Button onClick={() => handleEditOpen(school)}>Edit</Button>
                  </StyledTableCell>
                  {/* <StyledTableCell>
                  <IconButton aria-label="delete" onClick={() => handleDelete(school.id)}>
                    <DeleteIcon />
                  </IconButton>
                </StyledTableCell> */}
                  <StyledTableCell>
                    {school.role === 'superadmin' ? (
                      '----' // Show '----' if role is superadmin
                    ) : (
                      <IconButton aria-label="delete" onClick={() => handleDelete(school.id)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
}
