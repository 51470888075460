

// import React, { useState, useEffect } from 'react';
// import { getAuthToken } from '../../../../auth_token/authUtils';
// import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Alert, AlertTitle } from '@mui/material';
// import moment from 'moment';
// import CrownIcon from '@mui/icons-material/EmojiEvents'; // Crown-like icon from Material UI
// import Baseurl from '../../../../baseurl/baserurl';


// export default function StudentOfTheMonth() {
//   const [attendanceData, setAttendanceData] = useState([]);
//   const [alertMessage, setAlertMessage] = useState(''); // State to manage alert messages

//   useEffect(() => {
//     const fetchAttendanceData = async () => {
//       try {
//         const user = JSON.parse(localStorage.getItem('user'));
//         const school_id = user?.school_id;
//         const teacher_id = user?.teacher_id;

//         if (!school_id) {
//           setAlertMessage('School ID is missing.');
//           return;
//         }

//         const token = getAuthToken();
//         if (!token) {
//           setAlertMessage('Sorry, you are not authenticated. Please re-login.');
//           window.location.href = '/'; // Redirect to home page
//           window.location.reload(); // Force reload the page
//           return;
//         }

//         // Fetch data from the studentOfTheMonth API
//         const response = await fetch(`${Baseurl.baseURL}api/tution_attendance/studentOfTheMonth`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${token}`,
//           },
//           body: JSON.stringify({
//             school_id,
//             // teacher_id,
//           }),
//         });

//         if (response.ok) {
//           const data = await response.json();
//           if (data && data.data && Array.isArray(data.data)) {
//             setAttendanceData(data.data); // Store the attendance data
//           } else {
//             setAlertMessage('No data found or data is in an unexpected format.');
//           }
//         } else {
//           const error = await response.json();
//           setAlertMessage('Error fetching attendance data: ' + error.message);
//         }
//       } catch (error) {
//         setAlertMessage('An error occurred. Please try again.');
//       }
//     };

//     // Fetch data initially
//     fetchAttendanceData();

//     // Set up interval to fetch data every 50 seconds
//     const intervalId = setInterval(fetchAttendanceData, 50000);

//     // Clear interval on component unmount
//     return () => clearInterval(intervalId);
//   }, []);

//   // Get the current month
//   const currentMonth = moment().format('YYYY-MM');

//   // Aggregate data by student_name and count present/absent days
//   const aggregatedData = attendanceData.reduce((acc, record) => {
//     const recordMonth = moment(record.date).format('YYYY-MM');

//     if (recordMonth === currentMonth) {
//       const existingEntry = acc.find((entry) => entry.student_name === record.student_name);

//       if (existingEntry) {
//         // Update counts
//         if (record.status === 'Present') {
//           existingEntry.present_count += 1;
//         } else if (record.status === 'Absent') {
//           existingEntry.absent_count += 1;
//         }
//         existingEntry.total_days += 1; // Increment total days
//       } else {
//         // Create a new entry for the student
//         acc.push({
//           student_name: record.student_name,
//           present_count: record.status === 'Present' ? 1 : 0,
//           absent_count: record.status === 'Absent' ? 1 : 0,
//           total_days: 1, // Initialize total days
//         });
//       }
//     }

//     return acc;
//   }, []);

//   // Sort students by present_count in descending order (highest first)
//   const sortedData = [...aggregatedData].sort((a, b) => b.present_count - a.present_count);

//   return (
//     <Box>
//       {alertMessage && (
//         <Alert severity="error" onClose={() => setAlertMessage('')}>
//           <AlertTitle>Error</AlertTitle>
//           {alertMessage}
//         </Alert>
//       )}
//       <Typography variant="h6" gutterBottom>
//         Student Attendance for the Month
//       </Typography>
//       <TableContainer component={Paper} sx={{ maxHeight: 500, overflow: 'auto' }}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>Student Name</TableCell>
//               <TableCell align="center">Present Days</TableCell>
//               <TableCell align="center">Absent Days</TableCell>
//               <TableCell align="center">Total Days</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {sortedData.map((row, index) => (
//               <TableRow key={row.student_name} style={index === 0 ? { backgroundColor: '#a2fac0' } : {}}>
//                 <TableCell>
//                   {index === 0 && <CrownIcon style={{ marginRight: '8px', color: 'gold' }} />}
//                   {row.student_name}
//                 </TableCell>
//                 <TableCell align="center">{row.present_count}</TableCell>
//                 <TableCell align="center">{row.absent_count}</TableCell>
//                 <TableCell align="center">{row.total_days}</TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </Box>
//   );
// }


import React, { useState, useEffect } from 'react';
import { getAuthToken } from '../../../../auth_token/authUtils';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Alert, AlertTitle, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import moment from 'moment';
import CrownIcon from '@mui/icons-material/EmojiEvents'; // Crown-like icon from Material UI
import Baseurl from '../../../../baseurl/baserurl';

export default function StudentOfTheMonth() {
  const [attendanceData, setAttendanceData] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');
  const [selectedStudent, setSelectedStudent] = useState(''); // State for the selected student

  useEffect(() => {
    const fetchAttendanceData = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        const school_id = user?.school_id;

        if (!school_id) {
          setAlertMessage('School ID is missing.');
          return;
        }

        const token = getAuthToken();
        if (!token) {
          setAlertMessage('Sorry, you are not authenticated. Please re-login.');
          window.location.href = '/'; // Redirect to home page
          window.location.reload(); // Force reload the page
          return;
        }

        const response = await fetch(`${Baseurl.baseURL}api/tution_attendance/studentOfTheMonth`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ school_id }),
        });

        if (response.ok) {
          const data = await response.json();
          if (data && data.data && Array.isArray(data.data)) {
            setAttendanceData(data.data);
          } else {
            setAlertMessage('No data found or data is in an unexpected format.');
          }
        } else {
          const error = await response.json();
          setAlertMessage('Error fetching attendance data: ' + error.message);
        }
      } catch (error) {
        setAlertMessage('An error occurred. Please try again.');
      }
    };

    fetchAttendanceData();
    const intervalId = setInterval(fetchAttendanceData, 50000);
    return () => clearInterval(intervalId);
  }, []);

  const currentMonth = moment().format('YYYY-MM');

  const aggregatedData = attendanceData.reduce((acc, record) => {
    const recordMonth = moment(record.date).format('YYYY-MM');

    if (recordMonth === currentMonth) {
      const existingEntry = acc.find((entry) => entry.student_name === record.student_name);

      if (existingEntry) {
        if (record.status === 'Present') {
          existingEntry.present_count += 1;
        } else if (record.status === 'Absent') {
          existingEntry.absent_count += 1;
        }
        existingEntry.total_days += 1;
      } else {
        acc.push({
          student_name: record.student_name,
          present_count: record.status === 'Present' ? 1 : 0,
          absent_count: record.status === 'Absent' ? 1 : 0,
          total_days: 1,
        });
      }
    }

    return acc;
  }, []);

  const sortedData = [...aggregatedData].sort((a, b) => b.present_count - a.present_count);

  // Filter data based on selected student
  const filteredData = selectedStudent ? sortedData.filter(student => student.student_name === selectedStudent) : sortedData;

  return (
    <Box>
      {alertMessage && (
        <Alert severity="error" onClose={() => setAlertMessage('')}>
          <AlertTitle>Error</AlertTitle>
          {alertMessage}
        </Alert>
      )}
      <Typography variant="h6" gutterBottom>
        Student Attendance for the Month
      </Typography>
      
      <FormControl fullWidth variant="outlined" sx={{ marginBottom: 2 }}>
        <InputLabel id="student-select-label">Select Student</InputLabel>
        <Select
          labelId="student-select-label"
          value={selectedStudent}
          onChange={(e) => setSelectedStudent(e.target.value)}
          label="Select Student"
        >
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {sortedData.map(student => (
            <MenuItem key={student.student_name} value={student.student_name}>
              {student.student_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TableContainer component={Paper} sx={{ maxHeight: 500, overflow: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Student Name</TableCell>
              <TableCell align="center">Present Days</TableCell>
              <TableCell align="center">Absent Days</TableCell>
              <TableCell align="center">Total Days</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((row, index) => (
              <TableRow key={row.student_name} style={index === 0 ? { backgroundColor: '#a2fac0' } : {}}>
                <TableCell>
                  {index === 0 && <CrownIcon style={{ marginRight: '8px', color: 'gold' }} />}
                  {row.student_name}
                </TableCell>
                <TableCell align="center">{row.present_count}</TableCell>
                <TableCell align="center">{row.absent_count}</TableCell>
                <TableCell align="center">{row.total_days}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
