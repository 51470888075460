// import { NavLink } from "react-router-dom";
// import Footer from "../new/components/Footer";
// import MissionVision from "../new/components/MissionVision";
// import Testimonials from "../new/components/Testimonials";
// import WhyStudee from "../new/components/WhySchool";

// const Home = () => {
//     return (
//         <>
//             <div className="home">
//                 <p className="title">Welcome to Stu<span style={{ color: '#0b8219' }}>dee</span></p>
//                 <p className="info">We are delighted to have you visit our virtual home (Citadel of Excellence) - a place where knowledge thrives, dreams soar, and friendships flourish. At Studee, we believe in cultivating an environment that fosters academic excellence, nurtures creativity, and embraces diversity.</p>
//                 <NavLink to='/about'>Explore</NavLink>
//             </div>
//             <MissionVision />
//             <WhyStudee />
//             <Testimonials />
//             <Footer />
//         </>
//     );
// }

// export default Home;


import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Dialog, Container, Box, Avatar, Typography, TextField, Button, FormControlLabel, Checkbox } from '@mui/material';
import Footer from "../new/components/Footer";
import MissionVision from "../new/components/MissionVision";
import Testimonials from "../new/components/Testimonials";
import WhyStudee from "../new/components/WhySchool";
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Baseurl from '../../../../baseurl/baserurl';
import { setAuthToken } from '../../../../auth_token/authUtils';

const defaultTheme = createTheme();

const Home = () => {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false); // Dialog open/close

    const handleOpen = () => setOpen(true); // Open dialog
    const handleClose = () => setOpen(false); // Close dialog

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        try {
            const response = await axios.post(`${Baseurl.baseURL}api/auth/login`, {
                email: data.get('email'),
                password: data.get('password'),
            });

            const { token, user } = response.data;

            if (token) {
                setAuthToken(token, user); // Set your auth token here

                // Navigate based on role
                switch (user.role) {
                    case 'student':
                        navigate('/student-dashboard');
                        break;
                    case 'teacher':
                        navigate('/teacher-dashboard');
                        break;
                    case 'admin':
                        navigate('/admin-dashboard');
                        break;
                    case 'superadmin':
                        navigate('/super-admin-dashboard');
                        break;
                    case 'tution_teacher':
                        navigate('/tution_teacher-dashboard');
                        break;
                    case 'tution_student':
                        navigate('/tution_student-dashboard');
                        break;
                    default:
                        navigate('/');
                }

                window.location.reload(); // Refresh after login
            } else {
                alert('Login failed');
            }
        } catch (error) {
            console.error('Login error:', error);
            alert('An error occurred. Please try again.');
        }
    };

    return (
        <>
            <div className="home">
                <p className="title">Welcome to Stu<span style={{ color: '#0b8219' }}>dee</span></p>
                <p className="info">
                    Welcome to Studee, your Citadel of Excellence—where knowledge flourishes, aspirations take flight, and lifelong friendships are forged. We are committed to creating an inclusive environment that empowers every student to achieve academic excellence, fosters creativity through hands-on learning, and embraces the rich diversity of our community. Together, we aim to shape the leaders of tomorrow, ensuring that every student has access to the tools and support they need to thrive in their educational journey.
                </p>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '16px' }}>
                    <NavLink to='/about'>Explore</NavLink>
                    <Button variant="contained" onClick={handleOpen} style={{ marginLeft: '16px', marginTop: '15px' }}>Login</Button>
                </div>
            </div>
            <MissionVision />
            <WhyStudee />
            <Testimonials />
            <Footer />

            {/* Login Dialog */}
            <Dialog open={open} onClose={handleClose}>
                <Container component="main" maxWidth="xs">
                    <Box
                        sx={{
                            marginTop: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            position: 'relative',
                        }}
                    >
                        <Avatar
                            sx={{
                                m: 1,
                                bgcolor: 'secondary.main',
                                width: 56,
                                height: 56,
                                position: 'absolute',
                                top: '-28px',
                            }}
                        >
                            <img
                                src="/studee_logo.png"
                                alt="Avatar"
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                        </Avatar>
                        <Typography component="h1" variant="h5" sx={{ mt: 6 }}>
                            Welcome to Studee
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Remember me"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Sign In
                            </Button>
                        </Box>
                    </Box>
                </Container>
            </Dialog>
        </>
    );
}

export default Home;
