import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { getAuthToken } from '../../auth_token/authUtils';
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Baseurl from '../../baseurl/baserurl';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export default function CustomizedTables() {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const [mobile, setSchoolContact] = useState('');
  const [school_Name, setSchoolName] = useState('');
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [name, setName] = useState('');
  const [nameedit, setEditname] = useState('');

  const [studentName, setStudentName] = useState('');
  const [studentEmail, setStudentEmail] = useState('');
  const [studentContact, setStudentContact] = useState('');
  const [studentfees, setStudentFees] = useState('');
  const [fatherName, setFatherName] = useState('');
  const [motherName, setMotherName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [gender, setGender] = useState('');
  const [currentClass, setCurrentClass] = useState('');
  const [studentPic, setStudentPic] = useState(null); // Updated to handle file
  const [processedRows, setProcessedRows] = useState(new Set());
  // const [schools1, setSchools1] = useState([]);
  const [selectedSchoolId, setSelectedSchoolId] = useState(null);
  const [selectedTeacherId, setSelectedTeacherId] = useState(null);
  const [selectedStudentId, setSelectedStudentId] = useState(null);


  React.useEffect(() => {
    fetchSchools();
  }, []);

  const fetchSchools = async () => {
    const token = getAuthToken();

    if (!token) {
      alert('Sorry Your are not authenticated. Please re-login.');
      window.location.href = '/'; // Redirect to home page
      window.location.reload(); // Force reload the page
      return;
    }


    const user = JSON.parse(localStorage.getItem('user'));
    const school_id = user?.school_id;
    const teacher_id = user?.teacher_id;

    try {
      const response = await fetch(`${Baseurl.baseURL}api/addNewStudent/show_students`, {
        method: 'POST', // Changed from GET to POST
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          school_id: school_id,
          teacher_id: teacher_id,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setSchools(data);
      } else {
        const error = await response.json();
        alert('Error fetching schools: ' + error.message);
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleAddStudentOpen = (school) => {
    setSelectedSchoolId(school.school_id);
    setSelectedTeacherId(school.teacher_id);
    setSelectedStudentId(school.student_id);
    setStudentName(school.name || '');
    setEmail(school.email || '');
    setOpen1(true); // Assuming setOpen1 opens the dialog
  };


  const handleClose = () => {
    setOpen(false);
    setOpenEditModal(false);
    setOpen1(false);
  };


  const handleSubmit = async () => {
    const token = getAuthToken();
    const school_id = JSON.parse(localStorage.getItem('user')).school_id;
    const teacher_id = JSON.parse(localStorage.getItem('user')).teacher_id;
    const school_Name = JSON.parse(localStorage.getItem('user')).school_name;
    const mobile = JSON.parse(localStorage.getItem('user')).contact;


    if (!token) {
      alert('Sorry Your are not authenticated. Please re-login.');
      window.location.href = '/'; // Redirect to home page
      window.location.reload(); // Force reload the page
      return;
    }


    try {
      const response = await fetch(`${Baseurl.baseURL}api/addNewStudent/students`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          school_Name: school_Name,
          name: name,
          email: email,
          password: password,
          mobile: mobile,
          role: "student",
          school_id: school_id,
          teacher_id: teacher_id
        }),
      });

      if (response.ok) {
        fetchSchools();
        setSchoolName('');
        setEmail('');
        setPassword('');
        setSchoolContact('');
        setRole('');
        handleClose();
      } else {
        const error = await response.json();
        alert('Error adding school: ' + error.message);
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };

  const handleEditOpen = (school) => {
    setSelectedSchool(school);
    setSchoolName(school.school_name);
    setEmail(school.email);
    setPassword(school.password);
    setSchoolContact(school.mobile);
    setRole(school.role);
    setOpenEditModal(true);
  };

  const handleEditSubmit = async () => {
    const token = getAuthToken();

    if (!token) {
      alert('Sorry Your are not authenticated. Please re-login.');
      window.location.href = '/'; // Redirect to home page
      window.location.reload(); // Force reload the page
      return;
    }


    try {
      const response = await fetch(`${Baseurl.baseURL}api/addNewStudent/students/${selectedSchool.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          school_Name: school_Name,
          name: nameedit,
          email: email,
          password: password,
          mobile: mobile,
          role: role,
        }),
      });

      if (response.ok) {
        fetchSchools(); // Refresh the table after editing
        handleClose();
      } else {
        const error = await response.json();
        alert('Error updating school: ' + error.message);
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };

  const handleDelete = async (id) => {
    const token = getAuthToken();

    if (!token) {
      alert('Sorry Your are not authenticated. Please re-login.');
      window.location.href = '/'; // Redirect to home page
      window.location.reload(); // Force reload the page
      return;
    }


    try {
      const response = await fetch(`${Baseurl.baseURL}api/addNewStudent/students/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        fetchSchools(); // Refresh the list after deletion
      } else {
        alert('Error deleting school.');
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };

  const handleAddStudentSubmit = async () => {
    const token = getAuthToken();

    if (!token) {
      alert('Sorry Your are not authenticated. Please re-login.');
      window.location.href = '/'; // Redirect to home page
      window.location.reload(); // Force reload the page
      return;
    }


    const formData = new FormData();
    formData.append('student_name', studentName);
    formData.append('student_email', studentEmail);
    formData.append('student_contact', studentContact);
    formData.append('student_fees', studentfees);
    formData.append('student_father_name', fatherName);
    formData.append('student_mother_name', motherName);
    formData.append('date_of_birth', dateOfBirth);
    formData.append('gender', gender);
    formData.append('current_class', currentClass);
    formData.append('school_id', selectedSchoolId); // Use the selected school_id
    formData.append('teacher_id', selectedTeacherId); // Use the selected teacher_id
    formData.append('student_id', selectedStudentId); // Use the selected teacher_id

    if (studentPic) {
      formData.append('student_pic', studentPic); // Ensure the field name matches backend
    }

    try {
      const response = await fetch(`${Baseurl.baseURL}api/addProfile/addStudentProfile`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        setProcessedRows((prev) => new Set(prev).add(selectedSchoolId));
        setStudentName('');
        setStudentEmail('');
        setStudentContact('');
        setStudentFees('');
        setFatherName('');
        setMotherName('');
        setDateOfBirth('');
        setGender('');
        setCurrentClass('');
        setStudentPic(null);  
 
        fetchSchools();

        // Close the dialog
        handleClose();
      } else {
        const error = await response.json();
        alert('Error adding student: ' + error.message);
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };


  return (
    <>
      <Grid sx={{margin: '30px'}}>
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Create Student Login
        </Button>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Create Student Login</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="name"
              label="Student Name"
              type="text"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              margin="dense"
              id="email"
              label="Email"
              type="email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="dense"
              id="password"
              label="Password"
              type="password"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">Cancel</Button>
            <Button onClick={handleSubmit} color="primary">Submit</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openEditModal} onClose={handleClose}>
          <DialogTitle>Edit School</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="schoolName"
              label="School Name"
              type="text"
              fullWidth
              value={school_Name}
              onChange={(e) => setSchoolName(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="nameedit"
              label="Student Name"
              type="text"
              fullWidth
              value={nameedit}
              onChange={(e) => setEditname(e.target.value)}
            />
            <TextField
              margin="dense"
              id="email"
              label="Email"
              type="email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="dense"
              id="schoolContact"
              label="School Contact"
              type="text"
              fullWidth
              value={mobile}
              onChange={(e) => setSchoolContact(e.target.value)}
            />
            <TextField
              margin="dense"
              id="password"
              label="Password"
              type="password"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormControl fullWidth margin="dense">
              <InputLabel>Role</InputLabel>
              <Select value={role} onChange={(e) => setRole(e.target.value)}>
                <MenuItem value="student">Student</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">Cancel</Button>
            <Button onClick={handleEditSubmit} color="primary">Update School</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={open1} onClose={handleClose}>
          <DialogTitle>Add Student Profile</DialogTitle>
          <DialogContent>
            {/* Form fields */}
            <TextField
              margin="dense"
              id="studentName"
              label="Student Name"
              type="text"
              fullWidth
              value={studentName}
            // onChange={(e) => setStudentName(e.target.value)}
            />
            <TextField
              margin="dense"
              id="studentEmail"
              label="Student Email"
              type="email"
              fullWidth
              value={studentEmail}
              onChange={(e) => setStudentEmail(e.target.value)}
            />
            <TextField
              margin="dense"
              id="studentContact"
              label="Student Contact"
              type="text"
              fullWidth
              value={studentContact}
              onChange={(e) => setStudentContact(e.target.value)}
            />
            <TextField
              margin="dense"
              id="fatherName"
              label="Father's Name"
              type="text"
              fullWidth
              value={fatherName}
              onChange={(e) => setFatherName(e.target.value)}
            />
            <TextField
              margin="dense"
              id="motherName"
              label="Mother's Name"
              type="text"
              fullWidth
              value={motherName}
              onChange={(e) => setMotherName(e.target.value)}
            />
            <TextField
              margin="dense"
              id="dateOfBirth"
              label="Date of Birth"
              type="date"
              fullWidth
              value={dateOfBirth}
              onChange={(e) => setDateOfBirth(e.target.value)}
            />
            <TextField
              margin="dense"
              id="studentfees"
              label="Student Fees"
              type="text"
              fullWidth
              value={studentfees}
              onChange={(e) => setStudentFees(e.target.value)}
            />
            <FormControl fullWidth margin="dense">
              <InputLabel id="gender-label">Gender</InputLabel>
              <Select
                labelId="gender-label"
                id="gender"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              id="currentClass"
              label="Current Class"
              type="text"
              fullWidth
              value={currentClass}
              onChange={(e) => setCurrentClass(e.target.value)}
            />
            {/* <input
            type="file"
            accept="image/*"
            onChange={(e) => setStudentPic(e.target.files[0])} // Handle file input
          /> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAddStudentSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {/* <StyledTableCell>ID</StyledTableCell> */}
                <StyledTableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Edit</StyledTableCell>
                <StyledTableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Delete</StyledTableCell>
                <StyledTableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Add Student Profile</StyledTableCell>
                <StyledTableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Unique ID</StyledTableCell>
                <StyledTableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>School ID</StyledTableCell>
                <StyledTableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Teacher ID</StyledTableCell>
                <StyledTableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Student ID</StyledTableCell>
                <StyledTableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Name</StyledTableCell>
                <StyledTableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>School Name</StyledTableCell>
                <StyledTableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Email</StyledTableCell>
                <StyledTableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Password</StyledTableCell>
                <StyledTableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>School Contact</StyledTableCell>
                <StyledTableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Role</StyledTableCell>
                <StyledTableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Role ID</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schools.map((school) => (
                <StyledTableRow key={school.id}>
                  {/* <StyledTableCell component="th" scope="row">
                  {school.id}
                </StyledTableCell> */}
                  <StyledTableCell>
                    <Button onClick={() => handleEditOpen(school)}>Edit</Button>
                  </StyledTableCell>
                  {school.role !== 'teacher' ? (
                    <>
                      <StyledTableCell>
                        <IconButton aria-label="delete" onClick={() => handleDelete(school.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                        <Button variant="contained" color="primary" onClick={() => handleAddStudentOpen(school)} disabled={processedRows.has(school.student_id)}>
                          Add Student Profile
                        </Button>
                      </StyledTableCell>
                    </>
                  ) : (
                    <>
                      <StyledTableCell>---</StyledTableCell>
                      <StyledTableCell>---</StyledTableCell>
                    </>
                  )}
                  <StyledTableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{school.unique_id}</StyledTableCell>
                  <StyledTableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{school.school_id}</StyledTableCell>
                  <StyledTableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{school.teacher_id}</StyledTableCell>
                  <StyledTableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{school.student_id}</StyledTableCell>
                  <StyledTableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{school.name}</StyledTableCell>
                  <StyledTableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{school.school_name}</StyledTableCell>
                  <StyledTableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{school.email}</StyledTableCell>
                  <StyledTableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{school.password}</StyledTableCell>
                  <StyledTableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{school.school_contact}</StyledTableCell>
                  <StyledTableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{school.role}</StyledTableCell>
                  <StyledTableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{school.role_id}</StyledTableCell>

                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
}






