// import React, { useState, useEffect } from 'react';
// import {
//     Container,
//     TextField,
//     Button,
//     Typography,
//     Box,
//     Dialog,
//     DialogTitle,
//     DialogContent,
//     DialogActions,
//     Table,
//     TableBody,
//     TableCell,
//     TableContainer,
//     TableHead,
//     TableRow,
//     Paper,
// } from '@mui/material';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// import axios from 'axios';
// import Baseurl from '../../../baseurl/baserurl';

// const Notepad = () => {
//     const [noteContent, setNoteContent] = useState('');
//     const [title, setTitle] = useState('');
//     const [notes, setNotes] = useState([]);
//     const [open, setOpen] = useState(false);

//     // Load notes from the database
//     const fetchNotes = async () => {
//         try {
//             const token = localStorage.getItem('token');
//             const user = JSON.parse(localStorage.getItem('user'));
//             const school_id = user.school_id;

//             const response = await axios.post(`${Baseurl.baseURL}api/Notepaid/showNotePaid`, {
//                 school_id: school_id,
//             }, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                 },
//             });

//             if (response.data && response.data.application) {
//                 setNotes(response.data.application);
//             }
//         } catch (error) {
//             console.error('Error fetching notes:', error);
//         }
//     };


//     useEffect(() => {
//         fetchNotes();
//     }, []);

//     const saveNote = async () => {
//         try {
//             const token = localStorage.getItem('token');
//             const user = JSON.parse(localStorage.getItem('user'));

//             const payload = {
//                 subject: title,
//                 describe: noteContent,
//                 school_id: user.school_id,
//                 name: user.name,
//             };

//             await axios.post(`${Baseurl.baseURL}api/Notepaid/addNotePaid`, payload, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                 },
//             });
//             alert('Note saved successfully!');
//             setOpen(false); // Close the dialog after saving
//             fetchNotes(); // Fetch notes again to update the table
//         } catch (error) {
//             console.error('Error saving the note:', error);
//             alert('Error saving the note!');
//         }
//     };

//     return (
//         <Container maxWidth="md" sx={{ marginTop: 4 }}>
//             <Typography variant="h4" gutterBottom>
//                 My Notepad
//             </Typography>
//             <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
//                 Open Notepad
//             </Button>

//             <Dialog open={open} onClose={() => setOpen(false)}>
//                 <DialogTitle>My Notepad</DialogTitle>
//                 <DialogContent>
//                     <Box sx={{ marginBottom: 2 }}>
//                         <TextField
//                             label="Heading"
//                             variant="outlined"
//                             fullWidth
//                             value={title}
//                             onChange={(e) => setTitle(e.target.value)}
//                         />
//                     </Box>
//                     <ReactQuill
//                         theme="snow"
//                         value={noteContent}
//                         onChange={setNoteContent}
//                         placeholder="Start writing your note..."
//                         style={{ height: '300px', marginBottom: '20px' }}
//                     />
//                     <Button variant="contained" color="primary" onClick={saveNote}>
//                         Save Note
//                     </Button>
//                 </DialogContent>
//                 <DialogActions>
//                     <Button onClick={() => setOpen(false)} color="primary">
//                         Close
//                     </Button>
//                 </DialogActions>
//             </Dialog>

//             <Typography variant="h6" sx={{ marginTop: 4 }}>
//                 Saved Notes
//             </Typography>
//             <TableContainer component={Paper}>
//                 <Table>
//                     <TableHead>
//                         <TableRow>
//                             <TableCell>Title</TableCell>
//                             <TableCell>Description</TableCell>
//                         </TableRow>
//                     </TableHead>
//                     <TableBody>
//                         {notes.map((note) => (
//                             <TableRow key={note.id}>
//                                 <TableCell>{note.subject}</TableCell>
//                                 <TableCell>{note.describe}</TableCell>
//                             </TableRow>
//                         ))}
//                     </TableBody>
//                 </Table>
//             </TableContainer>
//         </Container>
//     );
// };

// export default Notepad;


// import React, { useState, useEffect } from 'react';
// import {
//     Container,
//     TextField,
//     Button,
//     Typography,
//     Box,
//     Dialog,
//     DialogTitle,
//     DialogContent,
//     DialogActions,
//     Table,
//     TableBody,
//     TableCell,
//     TableContainer,
//     TableHead,
//     TableRow,
//     Paper,
// } from '@mui/material';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// import axios from 'axios';
// import Baseurl from '../../../baseurl/baserurl';
// import { useHistory } from 'react-router-dom'; // Import useHistory for navigation

// const Notepad = () => {
//     const [noteContent, setNoteContent] = useState('');
//     const [title, setTitle] = useState('');
//     const [notes, setNotes] = useState([]);
//     const [open, setOpen] = useState(false);
//     const history = useHistory(); // Create a history instance

//     // Load notes from the database
//     const fetchNotes = async () => {
//         try {
//             const token = localStorage.getItem('token');
//             const user = JSON.parse(localStorage.getItem('user'));
//             const school_id = user.school_id;

//             const response = await axios.post(`${Baseurl.baseURL}api/Notepaid/showNotePaid`, {
//                 school_id: school_id,
//             }, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                 },
//             });

//             if (response.data && response.data.application) {
//                 setNotes(response.data.application);
//             }
//         } catch (error) {
//             console.error('Error fetching notes:', error);
//         }
//     };

//     useEffect(() => {
//         fetchNotes();
//     }, []);

//     const saveNote = async () => {
//         try {
//             const token = localStorage.getItem('token');
//             const user = JSON.parse(localStorage.getItem('user'));

//             const payload = {
//                 subject: title,
//                 describe: noteContent,
//                 school_id: user.school_id,
//                 name: user.name,
//             };

//             await axios.post(`${Baseurl.baseURL}api/Notepaid/addNotePaid`, payload, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                 },
//             });
//             alert('Note saved successfully!');
//             setOpen(false); // Close the dialog after saving
//             fetchNotes(); // Fetch notes again to update the table
//         } catch (error) {
//             console.error('Error saving the note:', error);
//             alert('Error saving the note!');
//         }
//     };

//     const handleShowNote = (noteId) => {
//         history.push(`/note/${noteId}`); // Navigate to the note detail page
//     };

//     return (
//         <Container maxWidth="md" sx={{ marginTop: 4 }}>
//             <Typography variant="h4" gutterBottom>
//                 My Notepad
//             </Typography>
//             <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
//                 Open Notepad
//             </Button>

//             <Dialog open={open} onClose={() => setOpen(false)}>
//                 <DialogTitle>My Notepad</DialogTitle>
//                 <DialogContent>
//                     <Box sx={{ marginBottom: 2 }}>
//                         <TextField
//                             label="Heading"
//                             variant="outlined"
//                             fullWidth
//                             value={title}
//                             onChange={(e) => setTitle(e.target.value)}
//                         />
//                     </Box>
//                     <ReactQuill
//                         theme="snow"
//                         value={noteContent}
//                         onChange={setNoteContent}
//                         placeholder="Start writing your note..."
//                         style={{ height: '300px', marginBottom: '20px' }}
//                     />
//                     <Button variant="contained" color="primary" onClick={saveNote}>
//                         Save Note
//                     </Button>
//                 </DialogContent>
//                 <DialogActions>
//                     <Button onClick={() => setOpen(false)} color="primary">
//                         Close
//                     </Button>
//                 </DialogActions>
//             </Dialog>

//             <Typography variant="h6" sx={{ marginTop: 4 }}>
//                 Saved Notes
//             </Typography>
//             <TableContainer component={Paper}>
//                 <Table>
//                     <TableHead>
//                         <TableRow>
//                             <TableCell>Title</TableCell>
//                             <TableCell>Description</TableCell>
//                             <TableCell>Action</TableCell> {/* Add Action column */}
//                         </TableRow>
//                     </TableHead>
//                     <TableBody>
//                         {notes.map((note) => (
//                             <TableRow key={note.id}>
//                                 <TableCell>{note.subject}</TableCell>
//                                 <TableCell>{note.describe}</TableCell>
//                                 <TableCell>
//                                     <Button variant="outlined" onClick={() => handleShowNote(note.id)}>
//                                         Show
//                                     </Button>
//                                 </TableCell>
//                             </TableRow>
//                         ))}
//                     </TableBody>
//                 </Table>
//             </TableContainer>
//         </Container>
//     );
// };

// export default Notepad;



import React, { useState, useEffect, useRef } from 'react';
import {
    Container,
    TextField,
    Button,
    Typography,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import Baseurl from '../../../baseurl/baserurl';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

const Notepad = () => {
    const [noteContent, setNoteContent] = useState('');
    const [title, setTitle] = useState('');
    const [notes, setNotes] = useState([]);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate(); // Create a navigate instance
    const quillRef = useRef(null);

    // Load notes from the database
    const fetchNotes = async () => {
        try {
            const token = localStorage.getItem('token');
            const user = JSON.parse(localStorage.getItem('user'));
            const school_id = user.school_id;

            const response = await axios.post(`${Baseurl.baseURL}api/Notepaid/showNotePaid`, {
                school_id: school_id,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data && response.data.application) {
                setNotes(response.data.application);
            }
        } catch (error) {
            console.error('Error fetching notes:', error);
        }
    };

    useEffect(() => {
        fetchNotes();
    }, []);

    const saveNote = async () => {
        try {
            const token = localStorage.getItem('token');
            const user = JSON.parse(localStorage.getItem('user'));

            const payload = {
                subject: title,
                describe: noteContent,
                school_id: user.school_id,
                name: user.name,
            };

            await axios.post(`${Baseurl.baseURL}api/Notepaid/addNotePaid`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            alert('Note saved successfully!');
            setOpen(false); // Close the dialog after saving
            fetchNotes(); // Fetch notes again to update the table
        } catch (error) {
            console.error('Error saving the note:', error);
            alert('Error saving the note!');
        }
    };

    const handleShowNote = (noteId) => {
        navigate(`/note/${noteId}`); // Use navigate to go to the note detail page
    };

    return (
        <Container maxWidth="auto" sx={{ marginTop: 4 }}>
            <Typography className="attendance-header" gutterBottom sx={{ textAlign: 'center', fontSize: '1.5rem' }}>
                My Notepad
            </Typography>
            <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                Add New Notepaid
            </Button>

            <Dialog open={open}>
                <Typography variant="h6" gutterBottom sx={{ textAlign: 'center', textDecoration: 'un' }}>
                    My Notepad
                </Typography>
                <DialogContent sx={{ mt: 2, width: 600 }}>
                    <Box sx={{ marginBottom: 2 }}>
                        <TextField
                            label="Heading"
                            variant="outlined"
                            fullWidth
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </Box>
                    <ReactQuill
                        theme="snow"
                        ref={quillRef} 
                        value={noteContent}
                        onChange={setNoteContent}
                        placeholder="Start writing your note..."
                        style={{ height: '300px', marginBottom: '20px' }}
                    />
                    <Button variant="contained" color="primary" onClick={saveNote} sx={{ mt: 4 }}>
                        Save Note
                    </Button>
                </DialogContent>
                {/* <Button variant="contained" color="primary" onClick={saveNote} sx={{ mt: 4 }}>
                    Save Note
                </Button> */}
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Typography variant="h6" sx={{ marginTop: 4 }}>
                Saved Notes
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Title</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {notes.map((note) => (
                            <TableRow key={note.id}>
                                <TableCell>{note.subject}</TableCell>
                                <TableCell>
                                    <Button variant="outlined" onClick={() => handleShowNote(note.id)}>
                                        Show
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default Notepad;
