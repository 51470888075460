
// Set token, user, and expiration time
export const setAuthToken = (token, user) => {
  const expirationTime = Date.now() + 60 * 60 * 1000;   
  // const expirationTime = Date.now() + 24 * 60 * 60 * 1000;
  localStorage.setItem('token', token);
  localStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem('expirationTime', expirationTime);
};

// Get the token if it's not expired, otherwise remove it
export const getAuthToken = () => {
  const expirationTime = localStorage.getItem('expirationTime');
  if (expirationTime && Date.now() > expirationTime) {
    // Token has expired, remove it
    removeAuthToken();
    return null;
  }
  return localStorage.getItem('token');
};

// Get the user if the token is not expired, otherwise remove the user
export const getUser = () => {
  const expirationTime = localStorage.getItem('expirationTime');
  if (expirationTime && Date.now() > expirationTime) {
    // Token has expired, remove the user
    removeAuthToken();
    return null;
  }
  return JSON.parse(localStorage.getItem('user'));
};

// Remove token, user, and expiration time from localStorage
export const removeAuthToken = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('expirationTime');
};

// Check if the token is expired based on its payload
export const isTokenExpired = (token) => {
  const decoded = JSON.parse(atob(token.split('.')[1]));
  return decoded.exp < Date.now() / 1000;
};
