import { whyStudee } from "../constrain"

const WhyStudee = () => {
  return (
    <div className="contentq">
      <h1>Why Studee?</h1>
      <div className="reasons">
        {whyStudee.map(reason => (
          <div className="reason" key={reason.id}>
            <div className="icon">
              {reason.icon}
            </div>
            <h2>{reason.title}</h2>
            <p>{reason.reason}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default WhyStudee