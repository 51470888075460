

import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Typography, Paper, CircularProgress, Snackbar, Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Dialog, DialogContent, IconButton } from '@mui/material';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import { getAuthToken } from '../../../auth_token/authUtils';
import Baseurl from '../../../baseurl/baserurl';


const AddApplicationForm = () => {
    const [formData, setFormData] = useState({
        subject: '',
        describe: '',
    });

    const [file, setFile] = useState(null);
    const [credentials, setCredentials] = useState({
        school_id: '',
        teacher_id: '',
        student_id: '',
        name: ''
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [applications, setApplications] = useState([]);
    const [imageDialogOpen, setImageDialogOpen] = useState(false); // State for the image dialog
    const [currentImage, setCurrentImage] = useState('');

    useEffect(() => {
        // Fetching user data from local storage
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            const { school_id, teacher_id, student_id, name } = user;
            setCredentials({
                school_id,
                teacher_id,
                student_id,
                name
            });

            // Fetch applications data
            fetchApplications(school_id, teacher_id);
            const intervalId = setInterval(() => {
                fetchApplications(school_id, teacher_id);
            }, 5000); // 5000 milliseconds = 5 seconds

            // Clear interval on component unmount
            return () => clearInterval(intervalId);
        } else {
            setError('User data not found. Please log in again.');
        }
    }, []);

    const fetchApplications = async (school_id, teacher_id) => {
        const authToken = getAuthToken();

        setError(null); // Clear previous errors

        try {
            const response = await axios.post(`${Baseurl.baseURL}api/application/getapplication`,
                JSON.stringify({
                    school_id,
                    teacher_id,
                }), {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                setApplications(response.data); // Assuming the response contains an array of applications
                setSuccess('Applications fetched successfully!');
            } else {
                setError('Unexpected response status: ' + response.status);
            }
        } catch (error) {
            if (error.response) {
                setError('Error fetching applications: ' + error.response.data.message || 'Please try again.');
            } else if (error.request) {
                setError('No response received. Please check your network connection.');
            } else {
                setError('Error: ' + error.message);
            }
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(null);

        const formDataToSend = new FormData();

        // Append credentials from the user object in local storage
        formDataToSend.append('school_id', credentials.school_id);
        formDataToSend.append('teacher_id', credentials.teacher_id);
        formDataToSend.append('student_id', credentials.student_id);
        formDataToSend.append('name', credentials.name);

        // Append form data
        formDataToSend.append('subject', formData.subject);
        formDataToSend.append('describe', formData.describe);

        // Append file
        if (file) {
            formDataToSend.append('result', file); // The file will be sent as 'result'
        }

        const authToken = getAuthToken();

        try {
            const response = await axios.post(`${Baseurl.baseURL}api/application/application`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                setSuccess('Application added successfully!');
                // Fetch updated applications data
                fetchApplications(credentials.school_id, credentials.teacher_id);
            } else {
                setError('Unexpected response status: ' + response.status);
            }
        } catch (error) {
            if (error.response) {
                setError('Error adding application: ' + error.response.data.message || 'Please try again.');
            } else if (error.request) {
                setError('No response received. Please check your network connection.');
            } else {
                setError('Error: ' + error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    const getFileType = (filename) => {
        return filename.split('.').pop().toLowerCase();
    };

    const handleFileClick = (url) => {
        setCurrentImage(url);
        setImageDialogOpen(true); // Open the dialog when image is clicked
    };

    const handleCloseDialog = () => {
        setImageDialogOpen(false); // Close the dialog
        setCurrentImage(''); // Reset current image
    };

    return (
        <Box>
            {/* Form Section */}
            {/* <Paper elevation={3} style={{ padding: '20px', maxWidth: '800px', margin: '20px auto' }}>
                <Typography variant="h6" gutterBottom>
                    Add Application
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                label="Subject"
                                name="subject"
                                fullWidth
                                value={formData.subject}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                label="Describe"
                                name="describe"
                                fullWidth
                                value={formData.describe}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button>
                                Upload Doc.
                                <TextField type="file" hidden onChange={handleFileChange} />
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={loading} // Disable button when loading
                                endIcon={loading && <CircularProgress size={24} />}
                            >
                                {loading ? 'Submitting...' : 'Submit'}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
 
                <Snackbar open={!!success} autoHideDuration={6000} onClose={() => setSuccess(null)}>
                    <Alert onClose={() => setSuccess(null)} severity="success">
                        {success}
                    </Alert>
                </Snackbar>

                <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
                    <Alert onClose={() => setError(null)} severity="error">
                        {error}
                    </Alert>
                </Snackbar>
            </Paper> */}

            {/* Applications List Section */}
            <Box style={{ margin: '20px 30px 10px 30px' }}>
                <Typography className="attendance-header" variant="h6" gutterBottom>
                    Applications List
                </Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow sx={{ backgroundColor: '#333' }}>
                                <TableCell sx={{ color: '#fff' }}>Student ID</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Name</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Subject</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Description</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Doc</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Created At</TableCell>
                            </TableRow>
                        </TableHead>
                        {/* <TableBody>
                            {applications.map((app) => (
                                <TableRow key={app.id}>
                                    <TableCell>{app.teacher_id}</TableCell>
                                    <TableCell>{app.student_id}</TableCell>
                                    <TableCell>{app.name}</TableCell>
                                    <TableCell>{app.subject}</TableCell>
                                    <TableCell>{app.describe}</TableCell>
                                    <TableCell>
                                        {app.result ? (
                                            <>
                                                {['png', 'jpeg', 'jpg'].includes(getFileType(app.result)) ? (
                                                    <div style={{ textAlign: 'center' }}>
                                                        <img
                                                            src={`${Baseurl.baseURL}api/${app.result}`}
                                                            alt="Result Preview"
                                                            style={{ maxWidth: '50px', maxHeight: '50px' }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <a
                                                        href={`${Baseurl.baseURL}api/${app.result}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        View Result
                                                    </a>
                                                )}
                                            </>
                                        ) : (
                                            'No Document'
                                        )}
                                    </TableCell>
                                    <TableCell>{new Date(app.createdAt).toLocaleString()}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody> */}
                        <TableBody>
                            {applications.length > 0 ? (
                                applications.map((app) => (
                                    <TableRow key={app.id}>
                                        {/* <TableCell>{app.teacher_id}</TableCell> */}
                                        <TableCell>
                                            {!app.student_id ? 'This application is yours' : app.student_id}
                                        </TableCell>
                                        <TableCell>{app.name}</TableCell>
                                        <TableCell>{app.subject}</TableCell>
                                        <TableCell>{app.describe}</TableCell>
                                        <TableCell>
                                            {app.result ? (
                                                ['png', 'jpeg', 'jpg'].includes(getFileType(app.result)) ? (
                                                    <div style={{ textAlign: 'center' }}>
                                                        <img
                                                            src={`${Baseurl.baseURL}api/${app.result.replace(/\\/g, '/')}`}
                                                            alt="Result Preview"
                                                            style={{ maxWidth: '50px', maxHeight: '50px', cursor: 'pointer' }}
                                                            onClick={() => handleFileClick(`${Baseurl.baseURL}api/${app.result.replace(/\\/g, '/')}`)} // Handle click for images
                                                        />
                                                    </div>
                                                ) : (
                                                    <a
                                                        href={`${Baseurl.baseURL}api/${app.result.replace(/\\/g, '/')}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{ color: '#00f' }} // Optional blue for links
                                                    >
                                                        View Result
                                                    </a>
                                                )
                                            ) : (
                                                'No Document'
                                            )}
                                        </TableCell>
                                        <TableCell>{new Date(app.createdAt).toLocaleString()}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                                        No applications available
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>

                    </Table>
                </TableContainer>
                <Dialog
                    open={imageDialogOpen}
                    onClose={handleCloseDialog}
                    fullWidth
                    maxWidth="md"
                >
                    <DialogContent>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleCloseDialog}
                            aria-label="close"
                            style={{ position: 'absolute', right: 8, top: 8 }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <img src={currentImage} alt="Fullscreen" style={{ width: '100%', height: 'auto' }} />
                    </DialogContent>
                </Dialog>
            </Box>
        </Box>
    );
};

export default AddApplicationForm;
