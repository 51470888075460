

// import React, { useState, useEffect } from 'react';
// import { TextField, Button, Grid, Typography, Paper, CircularProgress, Snackbar, Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box } from '@mui/material';
// import axios from 'axios';
// import { getAuthToken } from '../../../auth_token/authUtils';
// import Baseurl from '../../../baseurl/baserurl';


// const AddApplicationForm = () => {
//     const [formData, setFormData] = useState({
//         subject: '',
//         describe: '',
//     });

//     const [file, setFile] = useState(null);
//     const [credentials, setCredentials] = useState({
//         school_id: '',
//         teacher_id: '',
//         student_id: '',
//         name: ''
//     });

//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);
//     const [success, setSuccess] = useState(null);
//     const [applications, setApplications] = useState([]);

//     useEffect(() => {
//         // Fetching user data from local storage
//         const user = JSON.parse(localStorage.getItem('user'));
//         if (user) {
//             const { school_id, teacher_id, student_id, name } = user;
//             setCredentials({
//                 school_id,
//                 teacher_id,
//                 student_id,
//                 name
//             });

//             // Fetch applications data
//             fetchApplications(school_id, teacher_id);
//             const intervalId = setInterval(() => {
//                 fetchApplications(school_id, teacher_id);
//             }, 5000); // 5000 milliseconds = 5 seconds

//             // Clear interval on component unmount
//             return () => clearInterval(intervalId);
//         } else {
//             setError('User data not found. Please log in again.');
//         }
//     }, []);

//     const fetchApplications = async (school_id, teacher_id) => {
//         const authToken = getAuthToken();

//         setError(null); // Clear previous errors

//         try {
//             const response = await axios.post(`${Baseurl.baseURL}api/feedback/getfeedbackforteacher`,
//                 JSON.stringify({
//                     school_id,
//                     teacher_id,
//                 }), {
//                 headers: {
//                     'Content-Type': 'application/json',
//                     Authorization: `Bearer ${authToken}`,
//                 },
//             });

//             if (response.status === 200 && response.data.application) {
//                 setApplications(response.data.application); // Access the correct array inside the response
//                 setSuccess('Applications fetched successfully!');
//             } else {
//                 setError('Unexpected response status or format.');
//             }
//         } catch (error) {
//             if (error.response) {
//                 setError('Error fetching applications: ' + error.response.data.message || 'Please try again.');
//             } else if (error.request) {
//                 setError('No response received. Please check your network connection.');
//             } else {
//                 setError('Error: ' + error.message);
//             }
//         }
//     };


//     const getFileType = (filename) => {
//         return filename.split('.').pop().toLowerCase();
//     };

//     return (
//         <Box>
//             {/* Applications List Section */}
//             <Box style={{ margin: '20px 30px 10px 30px' }}>
//                 <Typography className="attendance-header" variant="h6" gutterBottom>
//                     Feedback List
//                 </Typography>
//                 <TableContainer component={Paper}> {/* Black background for the entire table */}
//                     <Table>
//                         <TableHead>
//                             <TableRow sx={{ backgroundColor: '#333' }}> {/* Dark background for table header */}
//                                 <TableCell sx={{ color: '#fff' }}>Student ID</TableCell>
//                                 <TableCell sx={{ color: '#fff' }}>Name</TableCell>
//                                 <TableCell sx={{ color: '#fff' }}>Subject</TableCell>
//                                 <TableCell sx={{ color: '#fff' }}>Description</TableCell>
//                                 <TableCell sx={{ color: '#fff' }}>Doc</TableCell>
//                                 <TableCell sx={{ color: '#fff' }}>Created At</TableCell>
//                             </TableRow>
//                         </TableHead>

//                         <TableBody>
//                             {applications.length > 0 ? (
//                                 applications.map((app) => (
//                                     <TableRow
//                                         key={app.id}
//                                         // sx={{
//                                         //     backgroundColor: '#000', // Black row background
//                                         //     '&:hover': { backgroundColor: '#222' }, // Slight hover effect
//                                         //     color: '#fff' // White text for the rows
//                                         // }}
//                                     >
//                                         <TableCell>
//                                             {!app.student_id ? 'This application is yours' : app.student_id}
//                                         </TableCell>
//                                         <TableCell>{app.name}</TableCell>
//                                         <TableCell>{app.subject}</TableCell>
//                                         <TableCell>{app.describe}</TableCell>
//                                         <TableCell>
//                                             {app.result ? (
//                                                 ['png', 'jpeg', 'jpg'].includes(getFileType(app.result)) ? (
//                                                     <div style={{ textAlign: 'center' }}>
//                                                         <img
//                                                             src={`${Baseurl.baseURL}api/${app.result.replace(/\\/g, '/')}`}
//                                                             alt="Result Preview"
//                                                             style={{ maxWidth: '50px', maxHeight: '50px' }}
//                                                         />
//                                                     </div>
//                                                 ) : (
//                                                     <a
//                                                         href={`${Baseurl.baseURL}api/${app.result.replace(/\\/g, '/')}`}
//                                                         target="_blank"
//                                                         rel="noopener noreferrer"
//                                                         style={{ color: '#00f' }} // Optional blue for links
//                                                     >
//                                                         View Result
//                                                     </a>
//                                                 )
//                                             ) : (
//                                                 'No Document'
//                                             )}
//                                         </TableCell>
//                                         <TableCell>{new Date(app.createdAt).toLocaleString()}</TableCell>
//                                     </TableRow>
//                                 ))
//                             ) : (
//                                 <TableRow sx={{ backgroundColor: '#000' }}>
//                                     <TableCell colSpan={7} sx={{ color: '#fff', textAlign: 'center' }}>
//                                         No applications available
//                                     </TableCell>
//                                 </TableRow>
//                             )}
//                         </TableBody>
//                     </Table>
//                 </TableContainer>

//             </Box>
//         </Box>
//     );
// };

// export default AddApplicationForm;





import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Typography, Paper, CircularProgress, Snackbar, Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // For closing the dialog
import axios from 'axios';
import { getAuthToken } from '../../../auth_token/authUtils';
import Baseurl from '../../../baseurl/baserurl';

const Feedback = () => {
    const [formData, setFormData] = useState({
        subject: '',
        describe: '',
    });

    const [file, setFile] = useState(null);
    const [credentials, setCredentials] = useState({
        school_id: '',
        teacher_id: '',
        student_id: '',
        name: ''
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [applications, setApplications] = useState([]);
    const [imageDialogOpen, setImageDialogOpen] = useState(false); // State for the image dialog
    const [currentImage, setCurrentImage] = useState(''); // State for the current image URL

    useEffect(() => {
        // Fetching user data from local storage
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            const { school_id, teacher_id, student_id, name } = user;
            setCredentials({
                school_id,
                teacher_id,
                student_id,
                name
            });

            // Fetch applications data
            fetchApplications(school_id, teacher_id);
            const intervalId = setInterval(() => {
                fetchApplications(school_id, teacher_id);
            }, 10000); // 5000 milliseconds = 5 seconds

            // Clear interval on component unmount
            return () => clearInterval(intervalId);
        } else {
            setError('User data not found. Please log in again.');
        }
    }, []);

    const fetchApplications = async (school_id, teacher_id) => {
        const authToken = getAuthToken();

        setError(null); // Clear previous errors

        try {
            const response = await axios.post(`${Baseurl.baseURL}api/feedback/getfeedbackforteacher`,
                JSON.stringify({
                    school_id,
                    teacher_id,
                }), {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200 && response.data.application) {
                setApplications(response.data.application); // Access the correct array inside the response
                setSuccess('Applications fetched successfully!');
            } else {
                setError('Unexpected response status or format.');
            }
        } catch (error) {
            if (error.response) {
                setError('Error fetching applications: ' + error.response.data.message || 'Please try again.');
            } else if (error.request) {
                setError('No response received. Please check your network connection.');
            } else {
                setError('Error: ' + error.message);
            }
        }
    };

    const getFileType = (filename) => {
        return filename.split('.').pop().toLowerCase();
    };

    const handleFileClick = (url) => {
        setCurrentImage(url);
        setImageDialogOpen(true); // Open the dialog when image is clicked
    };

    const handleCloseDialog = () => {
        setImageDialogOpen(false); // Close the dialog
        setCurrentImage(''); // Reset current image
    };

    return (
        <Box>
            {/* Applications List Section */}
            <Box style={{ margin: '20px 30px 10px 30px' }}>
                <Typography className="attendance-header" variant="h6" gutterBottom>
                    Feedback List
                </Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow sx={{ backgroundColor: '#333' }}>
                                <TableCell sx={{ color: '#fff' }}>Student ID</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Name</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Subject</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Description</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Doc</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Created At</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {applications.length > 0 ? (
                                applications.map((app) => (
                                    <TableRow key={app.id}>
                                        <TableCell>
                                            {!app.student_id ? 'This application is yours' : app.student_id}
                                        </TableCell>
                                        <TableCell>{app.name}</TableCell>
                                        <TableCell>{app.subject}</TableCell>
                                        <TableCell>{app.describe}</TableCell>
                                        <TableCell>
                                            {app.result ? (
                                                ['png', 'jpeg', 'jpg'].includes(getFileType(app.result)) ? (
                                                    <div style={{ textAlign: 'center' }}>
                                                        <img
                                                            src={`${Baseurl.baseURL}api/${app.result.replace(/\\/g, '/')}`}
                                                            alt="Result Preview"
                                                            style={{ maxWidth: '50px', maxHeight: '50px', cursor: 'pointer' }}
                                                            onClick={() => handleFileClick(`${Baseurl.baseURL}api/${app.result.replace(/\\/g, '/')}`)} // Handle click for images
                                                        />
                                                    </div>
                                                ) : (
                                                    <a
                                                        href={`${Baseurl.baseURL}api/${app.result.replace(/\\/g, '/')}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{ color: '#00f' }} // Optional blue for links
                                                    >
                                                        View Result
                                                    </a>
                                                )
                                            ) : (
                                                'No Document'
                                            )}
                                        </TableCell>
                                        <TableCell>{new Date(app.createdAt).toLocaleString()}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow sx={{ backgroundColor: '#000' }}>
                                    <TableCell colSpan={7} sx={{ color: '#fff', textAlign: 'center' }}>
                                        No applications available
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Fullscreen Image Dialog */}
                <Dialog
                    open={imageDialogOpen}
                    onClose={handleCloseDialog}
                    fullWidth
                    maxWidth="md"
                >
                    <DialogContent>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleCloseDialog}
                            aria-label="close"
                            style={{ position: 'absolute', right: 8, top: 8 }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <img src={currentImage} alt="Fullscreen" style={{ width: '100%', height: 'auto' }} />
                    </DialogContent>
                </Dialog>
            </Box>
        </Box>
    );
};

export default Feedback;
