// import React, { useEffect, useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import { BiHome, BiBookAlt, BiMessage, BiSolidReport, BiMenu, BiX } from 'react-icons/bi';
// import { Button } from '@mui/material';
// import "../style/sidebar.css";

// function Sidebar() {
//     const [isOpen, setIsOpen] = useState(false);
//     const navigate = useNavigate();

//     useEffect(() => {
//         const checkToken = () => {
//             const token = localStorage.getItem('token');
//             if (!token) {
//                 navigate('/');
//             }
//         }; 
//         checkToken(); 
//         const interval = setInterval(checkToken, 5000); 
//         return () => clearInterval(interval);
//     }, [navigate]); 

//     const handleLogout = async (event) => {
//         if (event) event.preventDefault();
//         try {

//             localStorage.removeItem('token');
//             localStorage.removeItem('tokenExpiration');
//             localStorage.removeItem('user');
//             setIsOpen(false);


//             setTimeout(() => {
//                 window.location.href = '/';
//             }, 1000);
//         } catch (error) {
//             console.error('Logout error:', error);
//         }
//     };

//     const toggleSidebar = () => {
//         setIsOpen(!isOpen);
//     };

//     const handleLinkClick = () => {
//         setIsOpen(false);  
//     };

//     return (
//         <div className={`sidebar-container ${isOpen ? 'open' : 'closed'}`}>
//             <div className="toggle-btn" onClick={toggleSidebar}>
//                 {isOpen ? <BiX size={30} /> : <BiMenu size={30} />}
//             </div>
//             {isOpen && (
//                 <div className="menu">
//                     <div className="logo">
//                         {/* <BiBookAlt className='logo-icon' /> */}
//                         <img src="/studee_logo.png" alt="Studee" className="logo-icon" width={70} height={70} style={{ borderRadius: '50px' }} />
//                         <h2>Studee</h2>
//                     </div>
//                     <div className="menu--list">
//                         <Link to="/teacher-dashboard" className="item" onClick={handleLinkClick}>
//                             <BiHome className='icon' />
//                             Dashboard
//                         </Link>
//                         <Link to="/attendance-dashboard" className="item" onClick={handleLinkClick}>
//                             <BiHome className='icon' />
//                             Attendance
//                         </Link>
//                         <Link to="/result-dashboard" className="item" onClick={handleLinkClick}>
//                             <BiHome className='icon' />
//                             Add Student Result
//                         </Link>
//                         <Link to="/teacher-detail-edit" className="item" onClick={handleLinkClick}>
//                             <BiSolidReport className='icon' />
//                             All Students
//                         </Link>
//                         <Link to="/teacher-application" className="item" onClick={handleLinkClick}>
//                             <BiSolidReport className='icon' />
//                             Application
//                         </Link>
//                         <Link to="/teacher-detail" className="item" onClick={handleLinkClick}>
//                             <BiMessage className='icon' />
//                             Create & Add Student
//                         </Link>

//                     </div>
//                     <div className="logout">
//                         <Button
//                             variant="contained"
//                             color="error"
//                             onClick={handleLogout}
//                         >
//                             Logout
//                         </Button>
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// }

// export default Sidebar;



import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BiHome, BiBookAlt, BiMessage, BiSolidReport, BiMenu, BiX } from 'react-icons/bi';
import { Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import "../style/sidebar.css";

function Sidebar() {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkToken = () => {
            const token = localStorage.getItem('token');
            if (!token) {
                navigate('/');
            }
        };
        checkToken();
        const interval = setInterval(checkToken, 5000);
        return () => clearInterval(interval);
    }, [navigate]);

    const handleLogout = async (event) => {
        if (event) event.preventDefault();
        try {
            localStorage.removeItem('token');
            localStorage.removeItem('tokenExpiration');
            localStorage.removeItem('user');
            setIsOpen(false);
            setTimeout(() => {
                window.location.href = '/';
            }, 1000);
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleLinkClick = () => {
        setIsOpen(false);
    };

    return (
        <div className={`sidebar-container ${isOpen ? 'open' : 'closed'}`}>
            <div className="toggle-btn" onClick={toggleSidebar}>
                {isOpen ? <BiX size={30} /> : <BiMenu size={30} />}
            </div>
            {isOpen && (
                <div className="menu">
                    <div className="logo">
                        <img src="/studee_logo.png" alt="Studee" className="logo-icon" width={70} height={70} style={{ borderRadius: '50px' }} />
                        <h2>Studee</h2>
                    </div>
                    <div className="menu--list">
                        <Link to="/teacher-dashboard" className="item" onClick={handleLinkClick}>
                            <BiHome className='icon' />
                            Dashboard
                        </Link>
                        <Link to="/attendance-dashboard" className="item" onClick={handleLinkClick}>
                            <BiHome className='icon' />
                            Attendance
                        </Link>
                        <Link to="/result-dashboard" className="item" onClick={handleLinkClick}>
                            <BiHome className='icon' />
                            Add Student Result
                        </Link>
                        <Link to="/teacher-detail-edit" className="item" onClick={handleLinkClick}>
                            <BiSolidReport className='icon' />
                            All Students
                        </Link>

                        <Accordion className="accordion-item">
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="application-content" id="application-header">
                                <BiSolidReport className="icon" />
                                Application
                            </AccordionSummary>
                            <AccordionDetails>
                                <Link to="/teacher-application" className="dropdown-item" onClick={handleLinkClick}>
                                    <BiSolidReport className="icon" />
                                    Application
                                </Link>
                                <Link to="/teacher-feedback" className="dropdown-item" onClick={handleLinkClick}>
                                    <BiSolidReport className="icon" />
                                    FeedBack
                                </Link>
                                <Link to="/teacher-complain" className="dropdown-item" onClick={handleLinkClick}>
                                    <BiSolidReport className="icon" />
                                    Complain
                                </Link>
                            </AccordionDetails>
                        </Accordion>


                        <Link to="/teacher-detail" className="item" onClick={handleLinkClick}>
                            <BiMessage className='icon' />
                            Create & Add Student
                        </Link>
                    </div>
                    <div className="logout">
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleLogout}
                        >
                            Logout
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Sidebar;
