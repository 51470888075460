import React from 'react';
import { FaLocationArrow, FaMapMarkerAlt, FaPhone } from 'react-icons/fa';

const ContactAddress = () => {
  return (
    <div className="address">
      <div className="add">
        {/* <div className="icon"><FaMapMarkerAlt /></div> */}
        <p> <FaMapMarkerAlt className="icon" />Sector-19A, Noida <br /><FaMapMarkerAlt className="icon" />Sector-19B, Noida <br /><FaMapMarkerAlt className="icon" />Sector-12, Noida <br /><FaMapMarkerAlt className="icon" />Sector-22, Noida</p>
      </div>
      <div className="add">
        {/* <div className="icon">
          <FaPhone  />
        </div> */}
        <p><FaPhone className="icon" />+91 9305676650 <br /><FaPhone className="icon" />+91 9044109228</p>
      </div>
      <div className="add">
        <div className="icon">
          <FaLocationArrow />
        </div>
        <p>
          <a href="https://studee.co.in" target="_blank" rel="noopener noreferrer">
            https://studee.co.in
          </a>
        </p>
      </div>
    </div>
  )
}

export default ContactAddress
